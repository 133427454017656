export type RegistrationField = 'FIRST_NAME' |
    'LAST_NAME' |
    'EMAIL' |
    'CELL' |
    'COMPANY_NAME' |
    'ADDRESS' |
    'BUSINESS_EIN';

export class RegistrationFields {
    public static readonly FirstName: RegistrationField = 'FIRST_NAME';
    public static readonly LastName: RegistrationField = 'LAST_NAME';
    public static readonly Email: RegistrationField = 'EMAIL';
    public static readonly Cell: RegistrationField = 'CELL';
    public static readonly CompanyName: RegistrationField = 'COMPANY_NAME';
    public static readonly Address: RegistrationField = 'ADDRESS';
    public static readonly BusinessEin: RegistrationField = 'BUSINESS_EIN';
}

