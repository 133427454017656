import React from 'react';
import './styles.scss';

const SmallHeader = () => {
     
    return (
        <div className='small-header'>
            <div className='logo' />
        </div>
    );
    
}

export default SmallHeader
