import {HttpService, IApiConfigurationService} from "@swipejobs/frontend-comms-services";

export interface IEmailService {
    requestUnsubscribe(notificationId: string): Promise<boolean>;
}

export class EmailService implements IEmailService {
    constructor(private apiConfigurationService: IApiConfigurationService,
                private httpService: HttpService) {
    }

    async requestUnsubscribe(notificationId: string): Promise<boolean> {
        const url = this.apiConfigurationService.buildApiUrl(`email/external/unsubscribe/${notificationId}`);

        try {
            // HttpService from frontend-comms-services runs fetch internally with interceptors
            const response = await this.httpService.post(url) as Response;
            return response.ok;
        } catch (error) {
            console.error('Error during unsubscription', error);
            return false;
        }
    }
}
