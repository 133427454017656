import React from 'react';
import './styles.scss';
import Header from '../../components/Header';
import Register from '../../components/Register';
import { CustomerRegistrationStore } from '../../stores/CustomerRegistrationStore';
import Marketing from '../../components/Marketing';
import {ConfigService} from "../../services/ConfigService";

interface IProps {
    customerRegistrationStore: CustomerRegistrationStore;
}

function RegistrationPage(props: IProps){

    const marketingContainer = () => {
        const config = ConfigService.getInstance().values;
        const marketingUrl = config.constants.marketingUrl;

        if (!marketingUrl) {
            return null;
        }

        return (
            <div className='content-marketing'>
                <Marketing />
            </div>
        )
    }

    return(
        <div className='registration-page'>
            <div className='content-page'>
                <Header />
                <Register store={props.customerRegistrationStore.registrationStore}
                          privacyPolicyStore={props.customerRegistrationStore.privacyPolicyStore}/>

            </div>
            {marketingContainer()}
        </div>
    )
}

export default RegistrationPage;
