import {
    IApiConfigurationService,
    IHttpService
} from '@swipejobs/frontend-comms-services';

export default class TokenService {

    private gstToken = '';
    private athToken = '';

    constructor(private apiConfigurationService: IApiConfigurationService,
                private httpService: IHttpService) {

    }

    get guestAuthorizationToken() {
        return `Bearer ${this.gstToken}`;
    }

    set userAuthorozationToken(token: string) {
        this.athToken = token;
    }

    get userAuthorizationToken() {

        if (!this.athToken){
            return null;
        }

        return `Bearer ${this.athToken}`;
    }

    async initGuestToken() {
        const api = this.apiConfigurationService.buildApiUrl( '/sessions/guest');
        const response = await this.httpService.get(api, {}, {
            'Request-Origin': 'CUSTOMER',
            'reqo': 'CUSTOMER-DESKTOP',         //todo replace these with proper comms service fix
            'pid': 'SJ'
        });
        const result = await response.json();
        this.gstToken = result.token;
    }
}
