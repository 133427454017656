import { action, computed, observable } from 'mobx';
import { AppDownloadStore } from './AppDownloadStore';
import { PinCodeStore } from './PinCodeStore';
import { RegistrationStore } from './RegistrationStore';
import {
    HttpService,
    IApiConfigurationService,
} from '@swipejobs/frontend-comms-services';
import { PinCodeService } from '../services/PinCodeService';
import { ClientTrackingService } from '../services/ClientTrackingService';
import TokenService from '../services/TokenService';
import history from '../services/history';
import { PinCodeModel } from '../models/PinCodeModels';
import { ClientTrackingNames } from '../models/ClientTracking';
import { URLParamStore } from './URLParamStore';
import { PrivacyPolicyService } from '../services/PrivacyPolicyService';
import { ReferralService } from '../services/ReferralService';
import { PrivacyPolicyStore } from './PrivacyPolicyStore';
import CustomerService from '../services/CustomerService';
import { ContactFormStore } from './ContactFormStore';
import DataTrackingService from '../services/DataTrackingService';

export class CustomerRegistrationStore {

    @observable public _showHeader: boolean = false;
    @observable public _showBackBtn: boolean = false;

    // stores
    public registrationStore: RegistrationStore;
    public pinCodeStore: PinCodeStore;
    public appDownloadStore: AppDownloadStore;
    public urlParamStore: URLParamStore;
    public privacyPolicyStore: PrivacyPolicyStore;
    public contactFormStore: ContactFormStore;

    //services
    private pinCodeService: PinCodeService;
    private referralService: ReferralService;
    private privacyPolicyService: PrivacyPolicyService;
    private customerService: CustomerService;
    private dataTrackingService: DataTrackingService;

    public clientTracking: ClientTrackingService;

    //model
    private pinCodeModel: PinCodeModel = {};

    constructor(private apiConfigurationService: IApiConfigurationService,
                private httpService: HttpService,
                private tokenService: TokenService) {

        this.clientTracking = ClientTrackingService.getInstance();
        this.clientTracking.trackEvent(ClientTrackingNames.AppStarted);

        this.pinCodeService = new PinCodeService(apiConfigurationService, httpService, tokenService);
        this.referralService = new ReferralService(apiConfigurationService, httpService, tokenService);
        this.privacyPolicyService = new PrivacyPolicyService(apiConfigurationService, httpService, tokenService);
        this.customerService = new CustomerService(apiConfigurationService, httpService, tokenService);
        this.dataTrackingService = new DataTrackingService(apiConfigurationService, httpService, tokenService);

        this.urlParamStore = new URLParamStore(this.referralService);
        this.privacyPolicyStore = new PrivacyPolicyStore(this.privacyPolicyService);
        this.contactFormStore = new ContactFormStore(
            this.customerService,
            this.dataTrackingService,
            this.urlParamStore
        );

        this.registrationStore = new RegistrationStore(
            this.customerService,
            this.pinCodeService,
            this.urlParamStore,
            this.navigateToCheckYourEmail,
            this.pinCodeModel
        );

        this.pinCodeStore = new PinCodeStore(
            this.pinCodeService,
            tokenService,
            this.urlParamStore,
            () => {},
            this.pinCodeModel
        );

        this.appDownloadStore = new AppDownloadStore();

        this.clientTracking.trackEvent(ClientTrackingNames.LandingPage, {landing: window.location.pathname});
    }

    @action.bound
    public showHeader() {

        if (!this._showHeader) {
            this._showHeader = true;
        }
    }

    @action.bound
    public hideHeader() {

        if (this._showHeader) {
            this._showHeader = false;
        }
    }

    @action.bound
    public showBackBtn() {
        if (!this._showBackBtn) {
            this._showBackBtn = true;
        }
    }


    @action.bound
    public hideBackBtn() {
        if (this._showBackBtn) {
            this._showBackBtn = false;
        }
    }

    @computed
    get hasTransactionId() {
        return this.pinCodeModel?.transactionId
    }

    @computed
    get isUserAuthenticated() {
        return !!this.tokenService.userAuthorizationToken;
    }

    // if you go back and forward ot will send a new pin code - so may as well wipe the current one
    navigateToCheckYourEmail = () =>  {
        this.pinCodeStore.wipePinCode();
        history.push('/check-your-email');
    };

}
