import React from 'react';
import { resources } from '../../resources/strings';
import './styles.scss';
import { Button } from '@material-ui/core';
import InformationalModal from '../Modals/Base/InformationalModal';
import TextField from '@material-ui/core/TextField/TextField';
import { ContactFormStore } from '../../stores/ContactFormStore';
import { observer } from 'mobx-react';
import { ContactFormFields } from '../../models/ContactForm';
import InputMask from 'react-input-mask';
import { LoaderOverlay } from '@swipejobs/frontend-react-core-web-components';
import 'date-fns';

interface IProps {
    store: ContactFormStore;
    showDate?: boolean;
    showTime?: boolean;
}

@observer
class ContactForm extends React.Component<IProps> {
    private readonly updateMap: { [type: string]: Function };

    constructor(props) {
        super(props);

        this.updateMap = {
            [ContactFormFields.Name]: this.props.store.updateName,
            [ContactFormFields.Email]: this.props.store.updateEmail,
            [ContactFormFields.Cell]: this.props.store.updateCellNumber,
            [ContactFormFields.Message]: this.props.store.updateMessage,
            [ContactFormFields.Time]: this.props.store.updateTime,
            [ContactFormFields.Date]: this.props.store.updateDate
        };

        this.props.store.updateShowTime(this.props.showTime);
        this.props.store.updateShowDate(this.props.showDate);
    }

    private itemChanged(type: string, event: any) {
        const value = event?.target?.value;
        this.updateMap[type](value);
        this.props.store.setIsValid();
    }

    private submitClicked() {
        this.props.store.submitClicked();
    }

    render() {
        const strings = resources.contactForm;
        return (
            <div className='contact-form'>
                <LoaderOverlay isLoading={this.props.store.isLoading}/>
                <div className='title'>{strings.title}</div>
                <div className='sub-title'>{strings.subTitle}</div>
                <div className='field-title'>{strings.name}</div>
                <TextField className='input-field'
                           placeholder={strings.name}
                           name='name'
                           variant='outlined'
                           value={this.props.store.name}
                           onChange={(e: any) => this.itemChanged(ContactFormFields.Name, e)}/>

                <div className='field-title'>{strings.email}</div>
                <TextField className='input-field'
                           placeholder={strings.email}
                           name='email'
                           variant='outlined'
                           value={this.props.store.email}
                           onChange={(e: any) => this.itemChanged(ContactFormFields.Email, e)}/>

                <div className='field-title'>{strings.phoneNumber}</div>
                <InputMask mask='(999) 999 9999'
                           value={this.props.store.cellNumber}
                           onChange={(e: any) => this.itemChanged(ContactFormFields.Cell, e)}>
                    {() => <TextField className='input-field'
                                      name='phoneNumber'
                                      placeholder={strings.phoneNumber}
                                      variant='outlined'/>}
                </InputMask>
                <div className='field-title'>{strings.message}</div>
                <TextField className='input-field message-field'
                           placeholder={strings.message}
                           multiline={true}
                           name='message'
                           rows={4}
                           rowsMax={4}
                           variant='outlined'
                           value={this.props.store.message}
                           onChange={(e: any) => this.itemChanged(ContactFormFields.Message, e)}/>
                {
                    (this.props.showDate || this.props.showTime) &&
                    <div>
                        <div className='field-title'>{strings.callBack}</div>
                        <div className='call-back'>
                            {
                                this.props.showDate &&
                                <TextField
                                    type='date'
                                    className='input-field date-picker'
                                    variant='outlined'
                                    value={this.props.store.date}
                                    onChange={(e: any) => this.itemChanged(ContactFormFields.Date, e)}
                                />
                            }
                            {
                                this.props.showTime &&
                                <TextField
                                    type='time'
                                    className='input-field time-picker'
                                    variant='outlined'
                                    inputProps={{ step: 900 }}
                                    value={this.props.store.time}
                                    onChange={(e: any) => this.itemChanged(ContactFormFields.Time, e)}
                                />
                            }
                        </div>
                    </div>
                }
                <Button className='button'
                        onClick={() => this.submitClicked()}
                        disabled={!this.props.store.isValid}>
                    {strings.submit}
                </Button>
                <InformationalModal isOpen={this.props.store.isModalOpen}
                                    onClose={this.props.store.closeModal}
                                    title={this.props.store.modalTitle}
                                    content={this.props.store.modalBody}/>
            </div>
        );
    };
}

export default ContactForm;
