import React from 'react';
import { resources } from '../../resources/strings';
import './styles.scss';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import { ConfigService } from '../../services/ConfigService';

interface IProps {
}

class ContactUs extends React.Component<IProps> {
    render() {
        const strings = resources.contactUs;
        const config = ConfigService.getInstance().values;
        const supportPhone = config.constants.SUPPORT_PHONE;
        const supportEmail = config.constants.SUPPORT_EMAIL;

        return (
            <div className='contact-us'>
                <div className='title'>
                    {strings.title}
                </div>
                <div className='sub-title'>
                    {strings.callSupportCenter}
                </div>
                <div className='highlighted'>
                    <CallIcon/>
                    <div className='highlighted-text'>
                        {supportPhone}
                    </div>
                </div>
                <div className='sub-title'>
                    {strings.emailSupportCenter}
                </div>
                <div className='highlighted'>
                    <EmailIcon/>
                    <div className='highlighted-text'>
                        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                    </div>
                </div>
            </div>
        );
    };
}

export default ContactUs;
