import React from 'react';
import './styles.scss';
import { resources } from '../../resources/strings';
import { Button } from '@material-ui/core';
import { PinCodeStore } from '../../stores/PinCodeStore';
import InformationalModal from '../Modals/Base/InformationalModal';
import { observer } from 'mobx-react';
import { LoaderOverlay } from '@swipejobs/frontend-react-core-web-components';
import { ClientTrackingNames } from '../../models/ClientTracking';
import ContactUs from '../ContactUs';

interface IProps {
    store: PinCodeStore;
}

@observer
class CheckYourEmail extends React.Component<IProps> {
    private strings = resources.checkYouEmailComponent;

    private resendEmail() {
        this.props.store.resendPinCodeClicked();
    }

    componentDidMount() {
        this.props.store.clientTracking.trackRender(ClientTrackingNames.PinSent);
    }

    render() {
        return(
            <div className='check-your-email'>
                <LoaderOverlay isLoading={this.props.store.isLoading}/>
                <div className='content'>
                    <div className='item'>
                        <div className='title'>{this.strings.title}</div>
                        <div className='sub-title'>{this.strings.subTitle}</div>
                        <Button className='button'
                                onClick={() => this.resendEmail()}>
                            {this.strings.resendEmail}
                        </Button>
                    </div>
                    <div className='item'>
                        <ContactUs />
                    </div>
                </div>
                <InformationalModal isOpen={this.props.store.isModalOpen}
                                    onClose={this.props.store.closeModal}
                                    title={this.props.store.modalTitle}
                                    content={this.props.store.modalBody}/>
            </div>
        )
    }
}

export default CheckYourEmail;
