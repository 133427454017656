import React from 'react';
import './styles.scss';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import LensIcon from '@material-ui/icons/Lens';

interface IProps {
    isValid: boolean;
    show: boolean;
}

class FieldValidationIcon extends React.Component<IProps> {
    render() {
        const {
            isValid,
            show
        } = this.props;

        return (
            <div>
                {
                    show &&
                    <div className='field-validation-icon-wrapper'>
                        <div className={`foreground ${isValid ? 'valid' : 'invalid'}`}>
                            {isValid && <CheckCircleIcon/>}
                            {!isValid && <CancelIcon/>}
                        </div>
                        <div className='background'>
                            <LensIcon/>
                        </div>
                    </div>
                }
            </div>
        );
    };
}

export default FieldValidationIcon;
