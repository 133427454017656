import { RequestContext } from './RequestContext';
import { Address } from './Address';

export class PinRequestReferrerPages {
    public static readonly InitialRegistrationPin: PinRequestReferrerPage = 'INITIAL_REGISTRATION_PIN';
    public static readonly InitialRegistrationResendPin: PinRequestReferrerPage = 'INITIAL_REGISTRATION_RESEND_PIN';
    public static readonly ForgotPasswordPin: PinRequestReferrerPage = 'FORGOT_PASSWORD_PIN';
    public static readonly ForgotPasswordResendPin: PinRequestReferrerPage = 'FORGOT_PASSWORD_RESEND_PIN';
    public static readonly ChangeCellNumberPin: PinRequestReferrerPage = 'CHANGE_CELL_NUMBER_PIN';
    public static readonly ChangeCellNumberResendPin: PinRequestReferrerPage = 'CHANGE_CELL_NUMBER_RESEND_PIN';
}

export type PinRequestReferrerPage = 'INITIAL_REGISTRATION_PIN' |
    'INITIAL_REGISTRATION_RESEND_PIN' |
    'FORGOT_PASSWORD_PIN' |
    'FORGOT_PASSWORD_RESEND_PIN' |
    'CHANGE_CELL_NUMBER_PIN' |
    'CHANGE_CELL_NUMBER_RESEND_PIN';

export interface PinCodeRequest {
    phoneNumber?: string;
    customerConsent?: boolean;
    currentPage?: PinRequestReferrerPage;
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    clientId?: string;
    context?: any;
    preferredNotificationType?: PinCodeNotificationType;
    requestContext?: RequestContext;
    requestType?: PinCodeRequestType;
}

export interface PinCodeResponse {
    transactionId: string;
    userAddress: string;
}

export type PinCodeServiceType = 'sms' | 'call' | 'email' | 'autoSelect';

export class PinCodeServiceTypes {
    public static readonly Sms: PinCodeServiceType = 'sms';
    public static readonly Call: PinCodeServiceType = 'call';
    public static readonly Email: PinCodeServiceType = 'email';
    public static readonly AutoSelect: PinCodeServiceType = 'autoSelect';
}

export type PinCodeNotificationType = 'SMS' | 'CALL' | 'EMAIL';

export const pinCodeNotificationTypeMap: {
    [key: string]: PinCodeNotificationType;
} = {
    [PinCodeServiceTypes.AutoSelect]: null,
    [PinCodeServiceTypes.Sms]: 'SMS',
    [PinCodeServiceTypes.Call]: 'CALL',
    [PinCodeServiceTypes.Email]: 'EMAIL'
};

export enum PinCodeRequestType {
    CHANGE_EMAIL,
    CHANGE_PHONE_NUMBER,
    FORGOT_PASSWORD,
    MIGRATE_LOGIN,
    REGISTER,
    VERIFY_EMAIL,
    VERIFY_PHONE_NUMBER
}

export type PinCodeContextType = 'EMAIL_CHANGED' |
    'EDIT_EMAIL' |
    'MIGRATE_LOGIN' |
    'INVITE_REGISTER' |
    'NEW_REGISTER' |
    'VERIFY_EMAIL' |
    'VERIFY_PHONE_NUMBER' |
    'FORGOT_PASSWORD_EMAIL';

export class PinCodeContextTypes  {
    public static readonly EmailChanged: PinCodeContextType = 'EMAIL_CHANGED';
    public static readonly EditEmail: PinCodeContextType = 'EDIT_EMAIL';
    public static readonly MigrateLogin: PinCodeContextType = 'MIGRATE_LOGIN';
    public static readonly InviteRegister: PinCodeContextType = 'INVITE_REGISTER';
    public static readonly NewRegister: PinCodeContextType = 'NEW_REGISTER';
    public static readonly VerifyEmail: PinCodeContextType = 'VERIFY_EMAIL';
    public static readonly ForgotPasswordEmail: PinCodeContextType = 'FORGOT_PASSWORD_EMAIL';
}

export interface SendCodeResponse {
    destination: string;
    transactionId?: string;
    errorCode?: string;
}

export interface PinCodeModel {
    phoneNumber?: string;
    email?: string;
    legalCheckbox?: boolean;
    smsConsent?: boolean;
    customerConsent?: boolean;
    externalId?: string;
    transactionId?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    ein?: string;
    businessAddress?: Address;
}
