import { Address } from '../models/Address';
import _ from 'lodash-es';
import { SuggestedAddress } from '../models/SuggestedAddress';

export class LocationUtil {

    private static ResultMap: {[type:string] : (location: Address, value: google.maps.GeocoderAddressComponent) => void} = {

        'street_number' : (location, value) => {
            location.streetNumber = value.long_name;
        },
        'route' : (location, value) => {
            location.route = value.long_name;
        },
        'locality' : (location, value) => {
            location.locality = value.long_name;
        },
        'administrative_area_level_2' : (location, value) => {
            location.administrativeAreaLevel2 = value.long_name;
            location.administrativeAreaLevel2Short = value.short_name;
        },
        'administrative_area_level_1' : (location, value) => {
            location.administrativeAreaLevel1 = value.long_name;
            location.administrativeAreaLevel1Short = value.short_name;
        },
        'country' : (location, value) => {
            location.country = value.long_name;
            location.countryShort = value.short_name;
        },
        'postal_code' : (location, value) => {
            location.postalCode = value.long_name;
        },
        'postal_code_suffix' : (location, value) => {
            location.postalCodeSuffix = value.long_name;
        }
    };

    // taken from angularjs codebase - todo refactor
    static getFormattedAddress(input: Address) {
        if (!input) {
            return '';
        }

        const streetNumber = input.streetNumber || '';
        const route = input.route || '';
        const locality = input.locality || '';
        const administrativeLevel1 = input.administrativeAreaLevel1Short || input.administrativeAreaLevel1 || '';
        const postalCode = input.postalCode || '';
        const formattedAddress = input.formattedAddress || '';

        if (!streetNumber && !route && formattedAddress) {
            return formattedAddress;
        }

        let address = `${streetNumber} ${route}, ${locality}, ${administrativeLevel1} ${postalCode}`;

        // replace wrong formatting if there was missing data.
        address = address.replace(', ,', ',').replace(' ,', ',');

        // remote "," from the start if applicable
        if (address.length >= 2 && address.substr(0, 2) === ', ') {
            address = address.substr(2, address.length - 2);
        }

        while (address.substr(0, 1) === ' ') {
            address = address.substr(1, address.length - 1);
        }

        address = address.replace(/^([0-9]+),/i, "$1");

        return address;
    }

    static parseGeoResultToAddress = (geoResult: google.maps.GeocoderResult) => {

        if (!geoResult) {
            console.error('GeocoderResult must be valid');
            return null;
        }

        let result: Address = {
        };

        _.forEach(geoResult.address_components, (component: google.maps.GeocoderAddressComponent) => {
            _.forEach(component.types, type =>{
                const parser = LocationUtil.ResultMap[type];
                parser && parser(result, component);
            });
        });

        if (geoResult.geometry && geoResult.geometry.location) {
            result.latitude = geoResult.geometry.location.lat();
            result.longitude = geoResult.geometry.location.lng();
        }

        result.placeId = geoResult.place_id;
        result.formattedAddress = geoResult.formatted_address;

        return result;
    };

    static isValidAddress(address: Address): boolean {
        if (!address) {
            return false;
        }

        return !!(address.streetNumber &&
            address.route &&
            address.country &&
            address.countryShort &&
            address.postalCode &&
            address.formattedAddress &&
            !!address.latitude && address.latitude >= -90 && address.latitude <= 90 &&
            !!address.longitude && address.longitude >= -180 && address.longitude <= 180 &&
            address.administrativeAreaLevel1 &&
            address.administrativeAreaLevel1Short);
    }

    static isValidSuggestedAddress(address: SuggestedAddress): boolean {
        if (!address) {
            return false;
        }

        return !!(address.streetNumber &&
            address.route &&
            address.country &&
            address.countryShort &&
            address.postalCode &&
            address.formattedAddress &&
            !!address.latitude && address.latitude >= -90 && address.latitude <= 90 &&
            !!address.longitude && address.longitude >= -180 && address.longitude <= 180 &&
            address.administrativeLevel1 &&
            address.administrativeLevel1Short);
    }
}
