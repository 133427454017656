import React from 'react';
import './styles.scss';
import { Button } from '@material-ui/core';
import { RegistrationStore } from '../../stores/RegistrationStore';
import { resources } from '../../resources/strings';
import TextField from '@material-ui/core/TextField/TextField';
import InputMask from 'react-input-mask';
import { observer } from 'mobx-react';
import { LoaderOverlay } from '@swipejobs/frontend-react-core-web-components';
import { stripPhoneNumberFormatting } from '../../utils/StripPhoneNumberFormatting';
import InformationalModal from '../Modals/Base/InformationalModal';
import { RegistrationFields } from '../../models/Registration';
import LocationSearchInput from '../LocationSearchInput';
import PrivacyPolicyDisclaimer from '../PrivacyPolicyDisclaimer';
import FieldValidationIcon from '../FieldValidationIcon';
import { isEmailValid } from '../../utils/EmailValidation';
import { PrivacyPolicyStore } from '../../stores/PrivacyPolicyStore';
import { ClientTrackingNames } from '../../models/ClientTracking';
import { ConfigService } from '../../services/ConfigService';

interface IProps {
    id?: string;
    store: RegistrationStore;
    privacyPolicyStore: PrivacyPolicyStore;
}

interface IState {
    isValid: boolean;
}

@observer
class Register extends React.Component<IProps, IState> {
    private readonly updateMap: { [type: string]: Function };
    private readonly showBusinessEinField: boolean;

    constructor(props) {
        super(props);
        const configs = ConfigService.getInstance().values;
        this.showBusinessEinField = configs.features.showBusinessEinField;

        this.state = {
            isValid: false
        };

        this.updateMap = {
            [RegistrationFields.FirstName]: this.props.store.updateFirstname,
            [RegistrationFields.LastName]: this.props.store.updateLastName,
            [RegistrationFields.Email]: this.props.store.updateEmail,
            [RegistrationFields.Cell]: this.props.store.updateCellNumber,
            [RegistrationFields.CompanyName]: this.props.store.updateCompanyName,
            [RegistrationFields.BusinessEin]: this.props.store.updateBusinessEin
        };
    }

    componentDidMount() {
        this.props.store.clientTracking.trackRender(ClientTrackingNames.Register);
        this.isValid();
    }

    private isValid() {
        const isValid = !!this.props.store.firstName &&
            !!this.props.store.lastName &&
            isEmailValid(this.props.store.email) &&
            this.isPhoneNumberValid(this.props.store.cellNumber) &&
            !!this.props.store.companyName &&
            !!this.props.store.selectedAddress;
        this.setState({ isValid });
    }

    private isPhoneNumberValid(phoneNumber: string) {
        return stripPhoneNumberFormatting(phoneNumber).length === 10;
    }

    private itemChanged(type: string, event: any) {
        const value = event?.target?.value;
        this.updateMap[type](value);
        this.isValid();
    }

    private onAddressChanged(address: string) {
        this.props.store.enteredAddress = address;
    }

    private onAddressSelected(geoResult: google.maps.GeocoderResult) {
        this.props.store.setSelectedGeolocationPoint(geoResult);
        this.isValid();

        if (!this.props.store.registrationRequestId) {
            this.props.store.initialiseRegistrationAsync();
        }
    }

    private submitClicked() {
        this.props.store.submitClicked(this.state.isValid);
    }

    render() {
        const strings = resources.registrationComponent;

        return (
            <div id='register'
                 className='registration'>
                <LoaderOverlay isLoading={this.props.store.isLoading}/>
                <div className='content'>
                    <div className='title'>{strings.title}</div>
                    <div className='sub-title'>{strings.subTitle}</div>
                    <div className='field-item'>
                        <TextField className='input-field'
                                   placeholder={strings.firstName}
                                   variant='outlined'
                                   value={this.props.store.firstName}
                                   onChange={(e: any) => this.itemChanged(RegistrationFields.FirstName, e)}/>
                        <FieldValidationIcon isValid={!!this.props.store.firstName}
                                             show={this.props.store.submitHasBeenClicked}/>
                    </div>
                    <div className='field-item'>
                        <TextField className='input-field'
                                   placeholder={strings.lastName}
                                   variant='outlined'
                                   value={this.props.store.lastName}
                                   onChange={(e: any) => this.itemChanged(RegistrationFields.LastName, e)}/>
                        <FieldValidationIcon isValid={!!this.props.store.lastName}
                                             show={this.props.store.submitHasBeenClicked}/>
                    </div>
                    <div className='field-item'>
                        <TextField className='input-field'
                                   placeholder={strings.email}
                                   variant='outlined'
                                   value={this.props.store.email}
                                   onChange={(e: any) => this.itemChanged(RegistrationFields.Email, e)}/>
                        <FieldValidationIcon isValid={isEmailValid(this.props.store.email)}
                                             show={this.props.store.submitHasBeenClicked}/>
                    </div>
                    <div className='field-item'>
                        <InputMask mask='(999) 999 9999'
                                   value={this.props.store.cellNumber}
                                   onChange={(e: any) => this.itemChanged(RegistrationFields.Cell, e)}>
                            {() => <TextField className='input-field'
                                              placeholder={strings.cellNumber}
                                              variant='outlined'/>}
                        </InputMask>
                        <FieldValidationIcon isValid={this.isPhoneNumberValid(this.props.store.cellNumber)}
                                             show={this.props.store.submitHasBeenClicked}/>
                    </div>
                    <div className='field-item'>
                        <TextField className='input-field'
                                   placeholder={strings.companyName}
                                   variant='outlined'
                                   value={this.props.store.companyName}
                                   onChange={(e: any) => this.itemChanged(RegistrationFields.CompanyName, e)}/>
                        <FieldValidationIcon isValid={!!this.props.store.companyName}
                                             show={this.props.store.submitHasBeenClicked}/>
                    </div>
                    <div className='field-item'>
                        <div className='input-field'>
                            <LocationSearchInput
                                placeholderLabel={strings.address}
                                enteredAddress={this.props.store.enteredAddress}
                                onAddressChanged={(address: string) => this.onAddressChanged(address)}
                                onClear={this.props.store.clearAddress}
                                onAddressSelected={(geoResult: google.maps.GeocoderResult) => this.onAddressSelected(geoResult)}>
                            </LocationSearchInput>
                        </div>
                        <FieldValidationIcon isValid={!!this.props.store.selectedAddress}
                                             show={this.props.store.submitHasBeenClicked}/>
                    </div>
                    {
                        this.showBusinessEinField &&
                        <TextField className='input-field'
                                   placeholder={strings.businessEin}
                                   variant='outlined'
                                   value={this.props.store.businessEin}
                                   onChange={(e: any) => this.itemChanged(RegistrationFields.BusinessEin, e)}/>
                    }
                    <div className='privacy-policy-wrapper'>
                        <PrivacyPolicyDisclaimer store={this.props.privacyPolicyStore}/>
                    </div>
                </div>
                <Button className='button'
                        disabled={!this.state.isValid || !this.props.store.registrationRequestId}
                        onClick={() => this.submitClicked()}>
                    {strings.buttonLabel}
                </Button>
                <InformationalModal isOpen={this.props.store.isModalOpen}
                                    onClose={this.props.store.closeModal}
                                    title={this.props.store.modalTitle}
                                    content={this.props.store.modalBody}/>
            </div>
        );
    }
}

export default Register;
