import { LazyLoaderUtil } from '../utils/LazyLoadingUtil';
import { ConfigService } from './ConfigService';

export interface IGeolocationService {
    initialiseGoogleMaps(): void;
}

export class GeoCoordinates {
    latitude: number;
    longitude: number;

    constructor(latitude: number, longitude: number) {
        this.latitude = latitude;
        this.longitude = longitude;
    }
}

export class GeolocationService implements IGeolocationService {
    private initialized: boolean = false;

    async initialiseGoogleMaps() {
        if (this.initialized) {
            return;
        }

        const config = ConfigService.getInstance().values;
        const placesApi = config?.constants?.googleApiKey;

        if (!placesApi) {
            console.error('Places API Key not found. Some features will not work.');
            return;
        }

        const googleMapsUrl = `https://maps.googleapis.com/maps/api/js?key=${placesApi}&libraries=places,geometry`;
        await LazyLoaderUtil.addScriptTagToHead(googleMapsUrl);
        this.initialized = true;
    }

    async getCurrentCoordinates(): Promise<GeoCoordinates> {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => resolve(new GeoCoordinates(position.coords.latitude, position.coords.longitude)),
                    (error) => reject(),
                    { timeout: 10000, enableHighAccuracy: true, maximumAge: 10000 });
            } else {
                reject();
            }
        });
    }

    geocodeLatLng(geoCoordinates: GeoCoordinates): Promise<google.maps.GeocoderResult> {
        return new Promise((resolve, reject) => {
            const geocoder = new google.maps.Geocoder();
            const latlng = new google.maps.LatLng(geoCoordinates.latitude, geoCoordinates.longitude);

            geocoder.geocode({ location: latlng, address: '' }, (results, status) => {
                if (status !== google.maps.GeocoderStatus.OK || !results[0]) {
                    reject();
                    return;
                }

                resolve(results[0]);
            });
        });
    }
}
