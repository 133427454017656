export type UrlParams = {
    rsCode: string;
    utm_campaign: string;
    programId: string;
    utm_medium: string;
    utm_source: string;
    utm_content: string;
    utm_id: string;
};


export type UrlParamType = 'rsCode' |
    'utm_term' |
    'utm_campaign' |
    'programId' |
    'utm_medium' |
    'utm_source' |
    'utm_content' |
    'utm_id';

export class UrlParamTypes {
    public static readonly RsCode: UrlParamType = 'rsCode';
    public static readonly Term: UrlParamType = 'utm_term';
    public static readonly Campaign: UrlParamType = 'utm_campaign';
    public static readonly ProgramId: UrlParamType = 'programId';
    public static readonly Medium: UrlParamType = 'utm_medium';
    public static readonly Source: UrlParamType = 'utm_source';
    public static readonly Content: UrlParamType = 'utm_content';
    public static readonly Id: UrlParamType = 'utm_id';
}

