import { PrivacyPolicyService } from '../services/PrivacyPolicyService';

// const templateId = 'customer-privacy-policy';

export class PrivacyPolicyStore {

    constructor(private privacyPolicyService: PrivacyPolicyService) {

    }

    // async getPrivacyPolicy() {
    //     const privacyPolicyDetails = await this.privacyPolicyService.getTemplateDataAsync(templateId);
    //     // console.log('%c **************** privacyPolicy *****************', 'background: blue; color: #fff', privacyPolicyDetails);
    // }
}
