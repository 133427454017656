import React, { useState } from 'react';
import './styles.scss';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { resources } from '../../resources/strings';
import InputMask from 'react-input-mask';
import { ConfigService } from '../../services/ConfigService';
import { stripPhoneNumberFormatting } from '../../utils/StripPhoneNumberFormatting';
import { usaStates } from 'typed-usa-states';
import { calendlyUrlMount } from '../../utils/calendlyUrlMount';
import _ from 'lodash';

const RequestADemo = () => {

    const defaultPhoneExtension = ConfigService.getInstance().values.constants.DEFAULT_PHONE_EXTENSION || '01';
    
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: defaultPhoneExtension,
        company: '',
        jobTitle: '',
        state: ''
    })

    const isValidForm = () => {
        return formData.firstName && !!formData.lastName && !!formData.email && isPhoneNumberValid(formData.phone) && !!formData.company
    }

    const isPhoneNumberValid = (phoneNumber: string) => {
        return stripPhoneNumberFormatting(phoneNumber).length >= 12;
    }

    const customerStrings = resources.customerLandingPage;

    const submitClicked = () => {
        const formattedPhone = _.trimStart(formData.phone.replace(/[^0-9.]/g, ''), '0');
        const generatedUrl = calendlyUrlMount({
            name: `${formData.firstName}%20${formData.lastName}`,
            email: formData.email,
            phone: formattedPhone,
            company: formData.company,
            jobTitle: formData.jobTitle,
            state: formData.state
        })

        window.location.href = generatedUrl;
    }

    const handleInputChange = (event: any, formType: string) => {
        const value = event.target.value;
        setFormData(prevStat => ({...prevStat, [formType]: value}))
    }

    const handleSelectChange = (event: any) => {
        const value = event.target.value as string;
        setFormData(prevStat => ({...prevStat, state: value}))
    }

    const renderStateList = () => {
        return usaStates.map(state => <MenuItem value={state.name}>{state.name}</MenuItem>)
    }

        return (
            <div id='register'
                 className='registration'>
                <div className='blue-bar'/>
                <div className='content'>
                    <h2 className='title'>{customerStrings.formTitle}</h2>
                    <div className='field-item'>
                        <TextField className='input-field-demo'
                                   label={customerStrings.firstNameLabel}
                                   value={formData.firstName}
                                   variant='outlined'
                                   onChange={(event: any) => handleInputChange(event, 'firstName')}/>
                    </div>
                    <div className='field-item'>
                        <TextField className='input-field-demo'
                                   label={customerStrings.lastNameLabel}
                                   value={formData.lastName}
                                   variant='outlined'
                                   onChange={(event: any) => handleInputChange(event, 'lastName')}/>
                    </div>
                    <div className='field-item'>
                        <TextField className='input-field-demo'
                                   variant='outlined'
                                   label={customerStrings.emailLabel}
                                   value={formData.email}
                                   onChange={(event: any) => handleInputChange(event, 'email')}/>
                    </div>
                    <div className='field-item'>
                        <InputMask mask='+99 (999) 999 9999'
                                   value={formData.phone}
                                   onChange={(event: any) => handleInputChange(event, 'phone')}>
                            {() => <TextField className='input-field-demo'
                                              variant='outlined'
                                              label={customerStrings.phoneLabel}/>}
                        </InputMask>
                    </div>
                    <div className='field-item'>
                        <TextField className='input-field-demo'
                                   variant='outlined'
                                   label={customerStrings.companyLabel}
                                   value={formData.company}
                                   onChange={(event: any) => handleInputChange(event, 'company')}/>
                    </div>
                    <div className='field-item'>
                        <TextField className='input-field-demo'
                                   variant='outlined'
                                   label={`${customerStrings.jobTitleLabel} ${customerStrings.jobTitleOptionalLabel}`}
                                   value={formData.jobTitle}
                                   onChange={(event: any) => handleInputChange(event, 'jobTitle')}/>
                    </div>
                    <div>
                    <FormControl className='state-form'>
                            <InputLabel id='demo-simple-select-label'
                                        className='select-placeholder'>
                                {customerStrings.stateLabel}
                            </InputLabel>
                            <Select value={formData.state}
                                    labelId="demo-simple-select-label"
                                    id='select-input'
                                    variant='outlined'
                                    className='select-input'
                                    onChange={handleSelectChange}>
                                {renderStateList()}
                            </Select>
                        </FormControl>
                    </div>
                    <Button className='button'
                        disabled={!isValidForm()}
                        onClick={() => submitClicked()}>
                    {customerStrings.buttonLabel}
                </Button>
                </div>

            </div>
        );
}

export default RequestADemo;
