import * as React from 'react';
import './styles.scss';
import PrivacyPolicyModal from '../Modals/PrivacyPolicyModal';
import ParsedLabel, { ReplacementMap } from '../Labels/ParsedLabel';
import { resources } from '../../resources/strings';
import { PrivacyPolicyStore } from '../../stores/PrivacyPolicyStore';

function Disclaimer(props: {
    text: string;
    openPrivacyPolicy: () => void;
}) {
    const replacements: ReplacementMap = {
        'privacy-policy-link': {
            type: 'tag',
            name: 'a',
            replacement: ({ children }) => {
                const linkChildren = children?.map((childNode) => childNode?.data ?? '') ?? null;
                return (
                    <a href="#"
                       onClick={props.openPrivacyPolicy}
                       style={{
                           textDecoration: 'underline',
                           cursor: 'pointer'
                       }}>
                        {linkChildren}
                    </a>
                );
            }
        }
    };

    return (
        <ParsedLabel htmlText={props.text}
                     replacements={replacements} />
    );
}

interface IProps {
    store: PrivacyPolicyStore;
}

function PrivacyPolicyDisclaimer(props: IProps) {
    const [isPrivacyPolicyShowing, setPrivacyPolicyShowing] = React.useState(false);
    const strings = resources.disclaimer;
    const disclaimerText = `<span class='privacy-policy-link'>${strings.text} <a id="privacy-policy-link">${strings.title}</a>.</span>`;

    return (
        <React.Fragment>
            <Disclaimer text={disclaimerText}
                        openPrivacyPolicy={() => setPrivacyPolicyShowing(true)} />
            <PrivacyPolicyModal store={props.store}
                                isOpen={isPrivacyPolicyShowing}
                                onClose={() => setPrivacyPolicyShowing(false)} />
        </React.Fragment>
    );
}

export default PrivacyPolicyDisclaimer;
