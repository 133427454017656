export type ContactFormField = 'NAME' |
    'EMAIL' |
    'CELL' |
    'TIME' |
    'DATE' |
    'MESSAGE';

export class ContactFormFields {
    public static readonly Name: ContactFormField = 'NAME';
    public static readonly Email: ContactFormField = 'EMAIL';
    public static readonly Cell: ContactFormField = 'CELL';
    public static readonly Message: ContactFormField = 'MESSAGE';
    public static readonly Time: ContactFormField = 'TIME';
    public static readonly Date: ContactFormField = 'DATE';
}

