import React from 'react';
import './styles.scss';
import { observer } from 'mobx-react';
import PlacesAutocomplete, {
    geocodeByAddress
} from 'react-places-autocomplete';
import { GeolocationService } from '../../services/GeolocationService';
import { SpinnerLoader } from '@swipejobs/frontend-react-core-web-components';
import CloseIcon from '@material-ui/icons/Close';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import LocationDisabledIcon from '@material-ui/icons/LocationDisabled';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { resources } from '../../resources/strings';
import { ConfigService } from '../../services/ConfigService';

interface IProps {
    onClear(): void;
    placeholderLabel?: string;
    onAddressSelected(address: google.maps.GeocoderResult): void;
    onAddressChanged(text: string);
    enteredAddress: string;
}

interface IState {
    noAddressesFound: boolean;
    locationEnabled: boolean;
    showCurrentLocation: boolean;
}

const zeroResults = 'ZERO_RESULTS';

@observer
export default class LocationSearchInput extends React.Component<IProps, IState> {
    private noAddressesFound = false;
    private geolocationService: GeolocationService;
    private searchOptions = {
        componentRestrictions: { country: 'us' }
    };
    private readonly enableCurrentLocation: boolean;


    constructor(props) {
        super(props);
        this.geolocationService = new GeolocationService();
        this.state = {
            showCurrentLocation: false,
            noAddressesFound: false,
            locationEnabled: false
        };
        const configs = ConfigService.getInstance().values;
        this.enableCurrentLocation = configs.features.enableCurrentLocation;
    }

    async componentDidMount() {
        await this.geolocationService.initialiseGoogleMaps();
        this.checkGoogleLoaded();
        if (this.enableCurrentLocation) {
            const coordinates = await this.geolocationService.getCurrentCoordinates();
            if (coordinates.latitude && coordinates.longitude) {
                this.setState({ locationEnabled: true });
            }
        }
    }

    private checkGoogleLoaded() {
        setTimeout(() => {
            if (window.google) {
                this.forceUpdate();
                return;
            }

            this.checkGoogleLoaded();
        }, 50);
    }

    private handleSelect = async address => {
        this.setState({ showCurrentLocation: false });
        const results = await geocodeByAddress(address);
        results && this.props.onAddressSelected(results[0]);
    };

    private onClear() {
        this.noAddressesFound = false;
        this.props.onClear();
    }

    private getClearButton = () => {
        if (this.props.enteredAddress) {
            return (
                <div onClick={() => this.onClear()}
                     className='clear'>
                    <CloseIcon/>
                </div>);
        }
        return null;
    };

    private getSuggestions = ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const strings = resources.locationSearchInput;
        const clearButton = this.getClearButton();
        suggestions = suggestions.slice(0,4);
        const inputProps = getInputProps({
            placeholder: this.props.placeholderLabel,
            className: 'location-search-input'
        });

        return (
            <div className='suggestion-contents'>
                <input value={this.props.enteredAddress}
                       onFocus={() => this.setState({ showCurrentLocation: true })}
                       {...inputProps}
                       onBlur={(e) => {
                           inputProps.onBlur(e);
                           setTimeout(() => this.setState({ showCurrentLocation: false }), 100 );
                       }}
                />
                <div className='spinner'>
                    {
                        loading && <SpinnerLoader backgroundColor='transparent'
                                                  isLoading={true}
                                                  size={15}/>
                    }
                </div>
                {clearButton}
                <div className='suggestion-container'>
                    {!this.noAddressesFound && suggestions.map(suggestion => {
                        return (
                            <div className='suggestion-item'
                                 {...getSuggestionItemProps(suggestion, {})}>
                                <div className='icon-wrapper'>
                                    <LocationSearchingIcon/>
                                </div>
                                <div className='description'>{suggestion.description}</div>
                            </div>
                        );
                    })}
                    {
                        this.enableCurrentLocation && this.state.showCurrentLocation && this.state.locationEnabled &&
                        <div className='suggestion-item'
                             onClick={() => this.currentLocationSelected()}>
                            <div className='icon-wrapper'>
                                <GpsFixedIcon/>
                            </div>
                            <div className='description'>{strings.currentLocation}</div>
                        </div>
                    }
                    {
                        this.noAddressesFound &&
                        <div className='suggestion-item'>
                            <div className='icon-wrapper'>
                                <LocationDisabledIcon/>
                            </div>
                            <div className='description'>{strings.noAddressesFound}</div>
                        </div>
                    }
                </div>
            </div>
        );
    };

    private async currentLocationSelected() {
        // this.$scope.loading = true;
        // this.trackClick(ClientTrackingNames.AddressSelectorCurrentLocationClick);
        try {
            // this.props
            const coords = await this.geolocationService.getCurrentCoordinates();
            const geoCodedResult = await this.geolocationService.geocodeLatLng(coords);

            // this.trackClick(ClientTrackingNames.AddressSelectorCurrentLocationUpdateSuccess);

            this.handleSelect(geoCodedResult.formatted_address);
            this.onClear();
        } catch (error) {
            // this.trackClick(ClientTrackingNames.AddressSelectorCurrentLocationUpdateError);
        }
    }

    private onAddressChanged(input: string) {
        this.noAddressesFound = false;
        this.props.onAddressChanged(input);
    }

    private handleError(error) {
        if (error === zeroResults) {
            this.noAddressesFound = true;
            this.forceUpdate();
        }
    }

    render() {
        const suggestions = this.getSuggestions;

        return (
            <div>
                {
                    window.google &&
                    <PlacesAutocomplete
                        onError={(e) => this.handleError(e)}
                        value={this.props.enteredAddress}
                        onChange={(input) => this.onAddressChanged(input)}
                        onSelect={this.handleSelect}
                        searchOptions={this.searchOptions}
                        debounce={500}>
                        {suggestions}
                    </PlacesAutocomplete>
                }
            </div>
        );
    }
}
