import {IEmailService} from "../services/EmailService";
import {action, observable} from "mobx";

export type UnsubscribeRequestStatus = 'idle' | 'loading' | 'success' | 'error';

export interface IUnsubscribeStore {
    readonly requestStatus: UnsubscribeRequestStatus;
    readonly notificationId: string;

    getNotificationIdFromParams(urlParams: URLSearchParams): string;

    setNotificationId(id: string): void;

    sendUnsubscribeRequest(): Promise<void>;
}

const NOTIFICATION_ID_QUERY_KEY = 'id';

export class UnsubscribeStore implements IUnsubscribeStore {
    notificationId: string;

    @observable requestStatus: UnsubscribeRequestStatus = 'idle';

    constructor(
        private emailService: IEmailService
    ) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        this.notificationId = this.getNotificationIdFromParams(urlParams);
    }

    getNotificationIdFromParams(urlParams: URLSearchParams): string {
        return urlParams.get(NOTIFICATION_ID_QUERY_KEY) || '';
    }

    setNotificationId(id: string) {
        this.notificationId = id;
    }

    @action.bound
    async sendUnsubscribeRequest(): Promise<void> {
        const notificationId = this.notificationId;
        if (!notificationId) {
            this.requestStatus = 'error';
            console.error('Cannot unsubscribe without a notification id');
            return;
        }

        console.debug('Requesting unsubscription with id', notificationId);

        this.requestStatus = "loading";

        const succeeded = await this.emailService.requestUnsubscribe(notificationId);
        if (!succeeded) {
            console.debug('Unsubscription failed', notificationId);
            this.requestStatus = 'error';
            return;
        }

        console.debug('Unsubscription succeeded', notificationId);

        this.requestStatus = "success";
    }
}