export const configs = {
    shared: {
        constants: {
            APPLICATION_NAME: "Customer Landing",
            APP_TYPE: "eapp",
            PARTNER_ID: "rs",
            USER_EMAIL_VALIDATION_REGEX_RULE: "^([\\w+-]+(?:\\.[\\w+-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,15}(?:\\.[a-z]{2})?)$",
            SUPPORT_PHONE: "214-765-9511",
            SUPPORT_EMAIL: "info@randstadusa.com",
            PIWIK_URL: "https://piwik.swipejobs.com/piwik/",
            PIWIK_CATEGORY: "customer_landing",
            WEBSITE_LINK: "https://rlc.randstadusa.com/for-business",
            APP_STORE_ANDROID_LINK: "https://play.google.com/store/apps/details?id=exchange.ondemand.randstad.customer",
            APP_STORE_IOS_LINK: "https://apps.apple.com/us/app/randstad-client/id1580658970",
            DEFAULT_LANDING_PAGE: 'LandingPage4',
            DEFAULT_PHONE_EXTENSION: '01',
            PARTNER_LOGO_URL: 'https://imgs.swipejobs.com/randstad/logo_blue_clear.png'
        },
        features: {
            showBusinessEinField: false,
            enableCurrentLocation: false
        },
        strings: {}
    },
    dev: {
        constants: {
            AUTH_INTERCEPTOR: "sj-exchange",
            HOST_URL: "https://randstad-dev.ondemand.exchange/customer/#/app/start",
            API_VERSION_URL: "https://s3.amazonaws.com/version-number/randstad-dev-uapp-config.json",
            BUNDLE_VERSION: "1.0.0",
            ENVIRONMENT: "dev",
            apiUrl: "https://api.rsus.dev.sj-exchange.com/v3/",
            googleApiKey: "AIzaSyC944RXN3_aYKrQ8K2m2dOYZe2UJ1KVQE0",
            marketingUrl: "",
            piwikTracking: {
                mobile: 79,
                desktop: 79
            },
            WORKER_APP_LINK: "https://randstad-dev-worker-landing.swipejobs.com/app-download"
        },
        features: {},
        strings: {

        }
    },
    preprod: {
        constants: {
            AUTH_INTERCEPTOR: "sj-exchange",
            HOST_URL: "https://randstad-preprod.ondemand.exchange/customer/#/app/start",
            API_VERSION_URL: "https://s3.amazonaws.com/version-number/randstad-preprod-uapp-config.json",
            BUNDLE_VERSION: "1.0.0",
            ENVIRONMENT: "preprod",
            apiUrl: "https://api.rsus.preprod.sj-exchange.com/v3/",
            googleApiKey: "AIzaSyC944RXN3_aYKrQ8K2m2dOYZe2UJ1KVQE0",
            marketingUrl: "",
            piwikTracking: {
                mobile: 80,
                desktop: 80
            },
            WORKER_APP_LINK: "https://randstad-preprod-worker-landing.swipejobs.com/app-download"
        },
        features: {},
        strings: {

        }
    },
    prod: {
        constants: {
            AUTH_INTERCEPTOR: "sj-exchange",
            HOST_URL: "https://randstad-prod.ondemand.exchange/customer/#/app/start",
            API_VERSION_URL: "https://s3.amazonaws.com/version-number/randstad-prod-uapp-config.json",
            BUNDLE_VERSION: "1.0.0",
            ENVIRONMENT: "prod",
            apiUrl: "https://api.rsus.prod.sj-exchange.com/v3/",
            googleApiKey: "AIzaSyBL6l-lGy-wgMeW_nqhu9iesfTHtmwKfCI",
            marketingUrl: "",
            piwikTracking: {
                mobile: 81,
                desktop: 81
            },
            WORKER_APP_LINK: "https://worker-landing.rs.on-demandexchange.com/app-download"
        },
        features: {},
        strings: {

        }
    },
};
