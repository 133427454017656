import React from 'react';
import './landingPage.scss';
import './landingPage2.scss';
import { CustomerRegistrationStore } from '../../stores/CustomerRegistrationStore';
import { ClientTrackingNames } from '../../models/ClientTracking';
import Header from '../../components/Header';
import { resources } from '../../resources/strings';
import ContactUs from '../../components/ContactUs';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import LensIcon from '@material-ui/icons/Lens';
import ContactForm from '../../components/ContactForm';

interface IProps {
    customerRegistrationStore: CustomerRegistrationStore;
    showDate?: boolean;
    showTime?: boolean;
}

class LandingPage2 extends React.Component<IProps> {
    private strings = resources.landingPage2;

    componentWillMount() {
        this.props.customerRegistrationStore.showHeader();
        this.props.customerRegistrationStore.clientTracking.trackRender(ClientTrackingNames.Landing, { page: 2 });
    }

    render() {
        return (
            <div className='landing-page landing-page-2 landing-page-with-image'>
                <div className='overlay' />
                <div className='landing-page-header'>
                    <Header isCentred={true}/>
                </div>
                <div className='landing-page-content'>
                    <div className='content-left'>
                        <div className='section'>
                            <div className='title'>{this.strings.section1.title}</div>
                            <div className='body'>{this.strings.section1.body}</div>
                        </div>
                        <div className='section'>
                            <div className='body'>
                                {
                                    this.strings.section2.items.map((item, index) => (
                                        <div key={index}>
                                            <div className='item-title'>{item.title}</div>
                                            <div className='item-body'>{item.body}</div>

                                        </div>)
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='content-right'>
                        <ContactForm store={this.props.customerRegistrationStore.contactFormStore}
                                     showDate={this.props.showDate}
                                     showTime={this.props.showTime}/>
                        <ContactUs/>
                    </div>
                </div>
                <a className='down-button'
                   href='#register'>
                    <div className='foreground'>
                        <ArrowDropDownCircleIcon />
                    </div>
                    <div className='background'>
                        <LensIcon />
                    </div>
                </a>
            </div>
        );
    }
}

export default LandingPage2;
