import css from './unsubscribePage.module.scss';
import React from 'react';
import {Observer} from "mobx-react";
import {autorun} from 'mobx';
import {IUnsubscribeStore} from "../../stores/UnsubscribeStore";
import {resources} from '../../resources/strings';
import {ConfigService} from "../../services/ConfigService";
import Helmet from 'react-helmet';

export type UnsubscribePageProps = {
    store: IUnsubscribeStore;
}

function isRequestResolved(store: IUnsubscribeStore): boolean {
    return ['success', 'error'].includes(store.requestStatus);
}

function UnsubscribePage(props: UnsubscribePageProps & { pathId?: string }) {
    const pathId = props.pathId ?? '';
    const {constants} = ConfigService.getInstance().values;

    React.useEffect(() => autorun(() => {
        if (!props.store.notificationId && pathId) {
            props.store.setNotificationId(pathId);
        }

        if (isRequestResolved(props.store)) {
            return;
        }

        props.store.sendUnsubscribeRequest();
        // tracked by autorun
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [pathId]);

    return (
        <>
            <Helmet>
                <link rel="preload" as="image" href={constants.PARTNER_LOGO_URL}/>
            </Helmet>
            <Observer>
                {() => {
                    const isResolved = isRequestResolved(props.store);
                    return (
                        <main className={css.main_container}>
                            <header>
                                <img
                                    className={css.partner_image}
                                    src={constants.PARTNER_LOGO_URL}
                                    alt={resources.unsubscribe.partnerLogoAltText}
                                />
                            </header>
                            <section>
                                {!isResolved && (
                                    <p>{resources.unsubscribe.loading}</p>
                                )}
                                {props.store.requestStatus === 'success' && (
                                    <>
                                        <p>{resources.unsubscribe.successfullyUnsubscribed}</p>
                                        <p>{resources.unsubscribe.wishToSubscribeAgain}</p>
                                    </>
                                )}
                                {props.store.requestStatus === 'error' && (
                                    <p>{resources.unsubscribe.failedToUnsubscribe}</p>
                                )}
                            </section>
                        </main>
                    );
                }}
            </Observer>
        </>
    );
}

export default UnsubscribePage;
