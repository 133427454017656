import { ClientTrackingService } from '../services/ClientTrackingService';
import { ClientTrackingNames } from '../models/ClientTracking';
import { action, observable } from 'mobx';
import { isAndroid, isIOS } from 'react-device-detect';
import {ConfigService} from "../services/ConfigService";

export class AppDownloadStore {

    public clientTracking: ClientTrackingService;
    @observable public canRedirect = true;
    @observable public redirectTimer = 10;
    private redirecting = false;

    constructor() {
        this.clientTracking = ClientTrackingService.getInstance();
    }

    iosBannerClicked = () => {
        this.clientTracking.trackClick(ClientTrackingNames.DownloadIos);
        // iOS Safari blocks window.open for non user initiated redirects
        // replacing current window/tab instead
        window.location.replace(this.iosStoreLink);
    };

    androidBannerClicked = () => {
        this.clientTracking.trackClick(ClientTrackingNames.DownloadAndroid);
        // works around pop-up blocking by changing the current window/tab
        window.location.replace(this.androidStoreLink);
    };

    @action.bound
    startRedirectTimerAt(timerValue: number) {
        this.redirectTimer = timerValue;
        this.startRedirectTimer();
    }

    @action.bound
    startRedirectTimer() {
        if (this.redirecting) {
            return;
        }

        this.redirecting = true;
        this.updateRedirectTimer();
    }

    private updateRedirectTimer() {
        setTimeout(() => {
            if (!this.redirectTimer && this.canRedirect) {
                this.redirectToStoreDownload();

                this.stopRedirectTimer();
                return;
            }

            if (this.canRedirect) {
                this.redirectTimer--;
                this.updateRedirectTimer();
            }

        }, 1000);
    }

    private redirectToStoreDownload() {
        if (isAndroid) {
            this.androidBannerClicked();
        }

        if (isIOS) {
            this.iosBannerClicked();
        }
    }

    @action.bound
    stopRedirectTimer() {
        this.canRedirect = false;
    }

    private get iosStoreLink() {

        const configs = ConfigService.getInstance().values;
        const constants = configs.constants;
        const link = constants.APP_STORE_IOS_LINK;

        if (!window.location.search) {
            return link;
        }

        const additionalParams = window.location.search;
        return this.combinePathWithQueryStrings(link, additionalParams);
    }

    private get androidStoreLink() {

        const configs = ConfigService.getInstance().values;
        const constants = configs.constants;
        const link = constants.APP_STORE_ANDROID_LINK;

        if (!window.location.search) {
            return link;
        }

        const additionalParams = window.location.search;
        return this.combinePathWithQueryStrings(link, additionalParams)
    }


    private combinePathWithQueryStrings(path, queryStrings) {
        if (!path) {
            console.error('Could not combine an invalid path');
            return '';
        }
        const hasQueryStrings = path.indexOf('?') > -1;
        const combiningQueryString = hasQueryStrings ? queryStrings.replace('?', '&') : queryStrings;
        const combinedPath = `${path}${combiningQueryString}`;
        return combinedPath;
    }
}
