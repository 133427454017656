import React from 'react';
import { CustomerRegistrationStore } from '../../stores/CustomerRegistrationStore';
import SmallHeader from '../../components/SmallHeader';
import ImageInformationCard from '../../components/ImageInformationCard';
import Footer from '../../components/Footer';
import RequestADemoFormCard from '../../components/RequestADemoFormCard';
import { resources } from '../../resources/strings';
import './styles.scss';


function SalesLandingPage(){

    const cardList = resources.customerLandingPage.imageCards;

    return(
        <div className='main-container'>
            <div className='centered-container'>
                <SmallHeader />
                <RequestADemoFormCard/>
                {cardList.map(cardInfo =>
                <ImageInformationCard title={cardInfo.title}
                                      image={cardInfo.image}
                                      content={cardInfo.description}
                                      imageOnRight={cardInfo.imageOnRight}/>
                )}
                <Footer/>
            </div>
        </div>
    )
}

export default SalesLandingPage;
