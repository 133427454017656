import {
    action,
    toJS,
    observable
} from 'mobx';
import { ClientTrackingService } from '../services/ClientTrackingService';
import { ClientTrackingNames } from '../models/ClientTracking';
import { PinCodeModel, PinCodeServiceTypes, PinRequestReferrerPages } from '../models/PinCodeModels';
import { PinCodeService } from '../services/PinCodeService';
import { resources } from '../resources/strings';
import { Address } from '../models/Address';
import { LocationUtil } from '../utils/LocationUtil';
import CustomerService from '../services/CustomerService';
import { URLParamStore } from './URLParamStore';
import { stripPhoneNumberFormatting } from '../utils/StripPhoneNumberFormatting';

export class RegistrationStore {

    @observable public firstName: string = '';
    @observable public lastName: string = '';
    @observable public email: string = '';
    @observable public cellNumber: string = '';
    @observable public companyName: string = '';
    @observable public businessEin: string = '';

    @observable public enteredAddress: string = '';
    @observable public selectedAddress: Address;

    @observable submitHasBeenClicked: boolean = false;
    @observable isLoading: boolean = false;

    @observable public isModalOpen: boolean = false;
    @observable public modalTitle: string = '';
    @observable public modalBody: string = '';
    @observable public  registrationRequestId: string;

    private strings = resources.registration;

    showDetailsNotMatchModal: boolean = false;

    public clientTracking: ClientTrackingService;

    constructor(private $customerService: CustomerService,
                private $pinCodeService: PinCodeService,
                private $urlParamStore: URLParamStore,
                private successNavigation: () => void,
                private pinCodeModel: PinCodeModel) {
        this.clientTracking = ClientTrackingService.getInstance();
    }

    @action.bound
    updateFirstname(text: string) {
        this.firstName = text;
    }

    @action.bound
    updateLastName(text: string) {
        this.lastName = text;
    }

    @action.bound
    updateEmail(text: string) {
        this.email = text;
    }

    @action.bound
    updateCellNumber(text: string) {
        this.cellNumber = text;
    }

    @action.bound
    updateCompanyName(text: string) {
        this.companyName = text;
    }

    @action.bound
    clearAddress() {
        this.enteredAddress = '';
        this.selectedAddress = null;
    }

    @action.bound
    public setSelectedGeolocationPoint(result: google.maps.GeocoderResult): Address {
        const parsedJobSiteAddress = LocationUtil.parseGeoResultToAddress(result);
        return this.setSelectedAddress(parsedJobSiteAddress);
    }

    @action.bound
    public setSelectedAddress(address: Address) {
        if (!LocationUtil.isValidAddress(address)) {
            console.log('Invalid Address Entered');
            return null;
        }

        this.selectedAddress = address;
        this.enteredAddress = LocationUtil.getFormattedAddress(this.selectedAddress);
        return this.selectedAddress;
    }


    @action.bound
    updateBusinessEin(text: string) {
        this.businessEin = text;
    }

    @action.bound
    async initialiseRegistrationAsync() {
        this.registrationRequestId = await this.$customerService.getRegistrationRequestId();

        if (!this.registrationRequestId) {
            this.openModal(this.strings.errorModalTitle, this.strings.errorInitialiseRegoMessage);
        }
    }

    @action.bound
    async submitClicked(isValid: boolean) {
        this.submitHasBeenClicked = true;

        if (!isValid) {
            return;
        }

        this.clientTracking.trackClick(ClientTrackingNames.RegisterNextClick);

        this.submitRegistrationAsync();
    }

    @action.bound
    async closeModal() {
        this.isModalOpen = false;
    }

    private async saveRegistrationFieldsAsync() {
        const getOptions = (name: string, value: string | Address, endpoint?: string) => ({
            endpoint: endpoint || name,
            name,
            value,
            registrationRequestId: this.registrationRequestId
        });

        await Promise.all(
            [
                this.$customerService.saveDataFieldAsync(getOptions('firstName', this.firstName)),
                this.$customerService.saveDataFieldAsync(getOptions('lastName', this.lastName)),
                this.$customerService.saveDataFieldAsync(getOptions('phoneNumber', stripPhoneNumberFormatting(this.cellNumber))),
                this.$customerService.saveDataFieldAsync(getOptions('emailAddress', this.email)),
                this.$customerService.saveDataFieldAsync(getOptions('companyName', this.companyName)),
                this.$customerService.saveDataFieldAsync(getOptions('ein', this.businessEin)),
                this.$customerService.saveDataFieldAsync(getOptions('address', this.selectedAddress, 'businessAddress')),
                this.$customerService.saveDataFieldAsync(getOptions('loginId', this.email )),
            ].map(promise => promise.catch(error => console.error(error)))
        );
    }

    private async submitRegistrationAsync() {
        this.isLoading = true;

        try {
            await this.saveRegistrationFieldsAsync();
        } catch (error) {
            this.isLoading = false;
            this.showErrorModal();
            return;
        }

        try {
            const response = await this.$customerService.validateBusinessArea(this.selectedAddress);
            if (!response.addressInAllowedArea) {
                this.isLoading = false;
                this.openModal(this.strings.errorModalTitle, this.strings.errorLocalityBody);
                return;
            }
        } catch (error) {
            this.isLoading = false;
            this.showErrorModal();
            return;
        }

        await this.sendPin();

        this.clientTracking.trackClick(ClientTrackingNames.PinCodeSendCodeEmail);

        this.isLoading = false;
    }

    private async sendPin() {
        try {
            this.pinCodeModel.phoneNumber = stripPhoneNumberFormatting(this.cellNumber);
            this.pinCodeModel.firstName = this.firstName;
            this.pinCodeModel.lastName = this.lastName;
            this.pinCodeModel.email = this.email;
            this.pinCodeModel.businessAddress = toJS(this.selectedAddress);
            this.pinCodeModel.companyName = this.companyName;
            this.pinCodeModel.ein = this.businessEin;

            const referralParams = this.$urlParamStore.getMarketingParams();

            const response = await this.$pinCodeService.trySendCodeAsync(
                this.pinCodeModel,
                {
                    pinCodeServiceType: PinCodeServiceTypes.Email,
                    referrerPage: PinRequestReferrerPages.InitialRegistrationPin,
                    referralParams,
                    registrationRequestId: this.registrationRequestId,
                    requestContext: null //RequestContextType.Registration
                }
            );

            const errorCode = response.errorCode;
            if (errorCode) {
                if (errorCode === 'ERR1004') {
                    const strings = resources.registration;
                    this.openModal(strings.errorModalTitle, strings.errorNumberUsedModalTitle);
                    return;
                }
                this.showErrorModal();
                return;
            }

            await this.$customerService.saveDataFieldAsync(
                {
                    endpoint: 'pinCodeTransactionId',
                    name: 'transactionId',
                    value: response.transactionId,
                    registrationRequestId: this.registrationRequestId
                }
            );

            this.clearForm();
            this.showSuccessModal();
        } catch (error) {
            console.error(error);
            this.showErrorModal();
            this.clientTracking.trackClick(ClientTrackingNames.RegisterValidationFailed, {cell: this.cellNumber});
        }
    }

    private clearForm() {
        this.updateFirstname('');
        this.updateLastName('');
        this.updateEmail('');
        this.updateCellNumber('');
        this.updateCompanyName('');
        this.updateBusinessEin('');
        this.clearAddress();
        this.submitHasBeenClicked = false;
        this.registrationRequestId = null;
    }

    private showSuccessModal() {
        this.openModal(this.strings.successModalTitle, this.strings.successModalBody);
    }

    private showErrorModal() {
        this.openModal(this.strings.errorModalTitle, this.strings.errorModalBody);
    }

    private openModal(title: string, body: string) {
        this.isModalOpen = true;
        this.modalTitle = title;
        this.modalBody = body;
    }
}
