import {
    HttpService,
    IApiConfigurationService
} from '@swipejobs/frontend-comms-services';
import TokenService from './TokenService';

export class PrivacyPolicyService {
    constructor(private apiConfigurationService: IApiConfigurationService,
                private httpService: HttpService,
                private tokenService: TokenService) {
    }

    async getTemplateDataAsync(id: string): Promise<any> { // ITemplateData
        try {
            // const templateData: {
            //     textLink: string
            //     tncId: string
            // } = await this.getTemplateAsync(id);

            // console.log('%c **************** templateData *****************', 'background: blue; color: #fff', templateData);

            // const templateLink = this.$sce.trustAsResourceUrl(templateData.textLink);
            // const template = await this.$templateRequest(templateLink, true);

            // return {
            //     tncId: templateData.tncId,
            //     template
            // }
        } catch (e) {
            // console.log('%c **************** error *****************', 'background: red; color: #fff', e);

            return null;
        }
    }

    private async getGuestToken(): Promise<string> {
        let count = 0;
        let authorization: string;

        const getAuthorizationAsync = async (): Promise<string> => {
            if (count === 5) {
                return null;
            }

            authorization = this.tokenService.guestAuthorizationToken;
            if (authorization !== 'Bearer ') {
                return authorization;
            }

            count++;

            return new Promise((resolve) => setTimeout(async () => {
                const authorization: string = this.tokenService.guestAuthorizationToken;
                resolve(authorization);
            }, 1000));
        };

        return new Promise(async (resolve) => {
            const authorization: string = await getAuthorizationAsync();
            if (authorization) {
                resolve(authorization);
                return
            }

            resolve(null);
        });

    }

    async getTemplateAsync(id: string): Promise<{
        app: string;
        textLink: string;
        tncId: string;
    }> {
        const api = this.apiConfigurationService.buildApiUrl( `tnc/external/getActive/${id}`);
        const authorization = await this.getGuestToken();
        const response = await this.httpService.get(api, {}, {
            authorization,
            'Request-Origin': 'CUSTOMER',
            'reqo': 'CUSTOMER-DESKTOP',         //todo replace these with proper comms service fix
            'pid': 'SJ'
        });
        return  await response.json();
    }
}
