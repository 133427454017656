import {
    HttpService,
    IApiConfigurationService
} from '@swipejobs/frontend-comms-services';
import TokenService from './TokenService';

export class ReferralService {

    constructor(private apiConfigurationService: IApiConfigurationService,
                private httpService: HttpService,
                private tokenService: TokenService) {
    }


    async createReferral(referralCode: string, programId: string, utm_id?: string) {
        try {

            let url = `referrals/referral/${referralCode}?programId=${programId}`;

            if (utm_id) {
                url = `${url}&utm_id=${utm_id}`;
            }

            const api = this.apiConfigurationService.buildApiUrl(url);
            const response = await this.httpService.post(api,
                {},
                {
                    authorization: this.tokenService.userAuthorizationToken,
                    'Request-Origin': 'CUSTOMER',
                    'reqo': 'CUSTOMER-DESKTOP',         //todo replace these with proper comms service fix
                    'pid': 'SJ'
                });

            const result = await response.json();

            return result;
        } catch (e) {
            // if this one fails just swallow the error
            console.error(e);
        }
    }

}
