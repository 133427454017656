import React from 'react';
import { resources } from '../../resources/strings';
import parse from 'html-react-parser';
import RequestADemo from '../RequestADemoForm';
import './styles.scss';

const RequestADemoFormCard = () => {

    const logoList = [
        'Logo_UPS.png',
        'Logo_CVS.png',
        'Logo_Porsche.png',
        'Logo_DHL.png',
        'Logo_Titos.png',
    ];

    const title = resources.customerLandingPage.pageTitle;
    const pageDescription = resources.customerLandingPage.pageDescription;

    return (
        <div className='card-form-container' id='top-container'>
            <div className='top-container'>
                <div className='left-side-staffing'>
                    <h1 className='title'>{parse(title)}</h1>
                    <p>{pageDescription}</p>
                    <img src='/images/Image_Title.png'/>
                </div>
                <div className='right-side-staffing'>
                    <RequestADemo/>
                </div>
            </div>
            <div className='partners-logos'>
                {logoList.map((logoPartner) => 
                    <img src={`/images/${logoPartner}`}/>
                )}
            </div>
        </div>
    );
    
}

export default RequestADemoFormCard
