import { ConfigService } from "../services/ConfigService";

interface ICalendlyParams {
    name: string;
    email: string;
    phone: string;
    company: string;
    jobTitle: string;
    state: string;
}

export const calendlyUrlMount = (params: ICalendlyParams) => {
    const configs = ConfigService.getInstance().values;
    const calendlyUrl = configs.constants.CALENDLY_URL;
    return `${calendlyUrl}?name=${params.name}&email=${params.email}&location=${params.phone}&a1=State%20name:%20${params.state},%20Company%20name:%20${params.company},%20Job%20Titles%20requested:%20${params.jobTitle}`;
}
