import React from 'react';
import InformationalModal from './Base/InformationalModal';
import { resources } from '../../resources/strings';
import { PrivacyPolicyStore } from '../../stores/PrivacyPolicyStore';
import {privacyPolicy} from "../../privacyPolicy";
import ReactHtmlParser from 'react-html-parser';

interface IProps {
    store: PrivacyPolicyStore;
    isOpen: boolean;
    onClose: () => void;
}

class PrivacyPolicyModal extends React.Component<IProps> {

    render() {
        const strings = resources.disclaimer;

        return (
            <InformationalModal {...this.props}
                                className='privacy-policy-modal'
                                title={strings.title}
                                content={ReactHtmlParser(privacyPolicy)} />
        );
    }
}

export default PrivacyPolicyModal;
