import React from 'react';
import './styles.scss';
import Header from '../../components/Header';
import CheckYourEmail from '../../components/CheckYourEmail';
import Marketing from '../../components/Marketing';
import { CustomerRegistrationStore } from '../../stores/CustomerRegistrationStore';
import {configs} from "../../config";
import {ConfigService} from "../../services/ConfigService";

interface IProps {
    customerRegistrationStore: CustomerRegistrationStore;
}

function CheckYourEmailPage(props: IProps){

    const marketingContainer = () => {
        const config = ConfigService.getInstance().values;
        const marketingUrl = config.constants.marketingUrl;

        if (!marketingUrl) {
            return null;
        }

        return (
            <div className='content-marketing'>
                <Marketing />
            </div>
        )
    }

    return(
        <div className='check-your-email-page'>
            <div className='content-page'>
                <Header />
                <CheckYourEmail store={props.customerRegistrationStore.pinCodeStore} />
            </div>
            {marketingContainer()}
        </div>
    )
}

export default CheckYourEmailPage;
