export type ClientTrackingActionType = 'click' | 'data' | 'page_view';

export class ClientTrackingActions {
    static readonly Click: ClientTrackingActionType = 'click';
    static readonly Data: ClientTrackingActionType = 'data';
    static readonly Render: ClientTrackingActionType = 'page_view';
}

export type ClientTrackingNameType = 'start' |
    'landing-page' |
    'register_next_click' |
    'register_validation_fail' |
    'pin_sent_send_code_email' |
    'pin_sent_resent_email' |
    'pin_confirm_validation_fail' |
    'pin_confirm_invalid_code' |
    'download_app_registration_download_ios' |
    'download_app_registration_download_android' |
    'landing' |
    'register' |
    'pin_sent'|
    'contact_form_submit' |
    'contact_form_success' |
    'contact_form_fail' |
    'download_app_registration' |
    'customer_marketing_tracking' |
    'download-app-registration';

export class ClientTrackingNames {

    //events
    static readonly AppStarted: ClientTrackingNameType = 'start';
    static readonly LandingPage: ClientTrackingNameType = 'landing-page';

    static readonly RegisterNextClick: ClientTrackingNameType = 'register_next_click';
    static readonly RegisterValidationFailed: ClientTrackingNameType = 'register_validation_fail';
    static readonly PinCodeSendCodeEmail: ClientTrackingNameType = 'pin_sent_send_code_email';
    static readonly PinResendCode: ClientTrackingNameType = 'pin_sent_resent_email';
    static readonly MarketingTracking: ClientTrackingNameType = 'customer_marketing_tracking';
    static readonly ContactFormSubmit: ClientTrackingNameType = 'contact_form_submit';
    static readonly ContactFormSuccess: ClientTrackingNameType = 'contact_form_success';
    static readonly ContactFormFail: ClientTrackingNameType = 'contact_form_fail';

    //page rendering
    static readonly Landing: ClientTrackingNameType = 'landing';
    static readonly Register: ClientTrackingNameType = 'register';
    static readonly PinSent: ClientTrackingNameType = 'pin_sent';

    static readonly DownloadIos: ClientTrackingNameType = 'download_app_registration_download_ios';
    static readonly DownloadAndroid: ClientTrackingNameType = 'download_app_registration_download_android';

    static readonly DownloadAppRegistration: ClientTrackingNameType = 'download-app-registration'

}


