import {
    HttpService,
    IApiConfigurationService
} from '@swipejobs/frontend-comms-services';
import TokenService from './TokenService';
import { v4 as uuidv4 } from 'uuid';
import { Address } from '../models/Address';

const newCustomer = 'NEW_CUSTOMER';
const contactFormUrl = 'https://formspree.io/mnqgavjz';

export interface ISubmitContactFormParams {
    name: string;
    emailAddress: string;
    phoneNumber: string;
    message: string;
    date?: string;
    time?: string;
    utmId: string;
    rsCode: string;
    utmCampaign: string;
    programId: string;
    utmMedium: string;
    utmSource: string;
    registrationUrl: string;
}

export default class CustomerService {
    constructor(private apiConfigurationService: IApiConfigurationService,
                private httpService: HttpService,
                private tokenService: TokenService) {
    }

    async saveDataFieldAsync(options: {
        endpoint: string;
        name: string;
        value: any;
        registrationRequestId: string;
    }) {
        const api = this.apiConfigurationService.buildApiUrl( `customers/registration/${options.registrationRequestId}/${options.endpoint}`);
        const body = { [options.name]: options.value };
        await this.httpService.put(api, body, this.getHeaders());
    }

    async getRegistrationRequestId() {
        try {
            const result = await this.initialiseRegistrationAsync();
            return result.requestId;
        } catch (error) {
            return null;
        }
    }

    private async initialiseRegistrationAsync() {
        const requestId = uuidv4();
        const api = this.apiConfigurationService.buildApiUrl( `customers/registration`);
        const body = {
            requestId,
            registrationType: newCustomer
        };
        await this.httpService.post(api, body, this.getHeaders());
        return {
            requestId
        };
    }

    async validateBusinessArea(jobSiteAddress: Address) {
        const api = this.apiConfigurationService.buildApiUrl( '/customer-profile/external/addresses/validateBusinessArea');
        const response = await this.httpService.post(api, jobSiteAddress, this.getHeaders());
        const result = await response.json();
        return result as { addressInAllowedArea: boolean }
    }

    async submitContactForm(params: ISubmitContactFormParams) {
        const headers = { Accept: 'application/json' };
        const response = await this.httpService.post(contactFormUrl, params, headers);
        await response.json();
    }

    private getHeaders() {
        const authorization = this.tokenService.guestAuthorizationToken;
        return {
            authorization,
            'Request-Origin': 'CUSTOMER',
            'reqo': 'CUSTOMER-DESKTOP',         //todo replace these with proper comms service fix
            'pid': 'SJ'
        }
    }
}
