//xxx copied from Exchange ordering - branch FEO-33 (not merged yet)


import _ from 'lodash';
import { Window } from '../models/WindowInterface';
import { LazyLoaderUtil } from '../utils/LazyLoadingUtil';
import {
    ClientTrackingActions,
    ClientTrackingActionType,
    ClientTrackingNameType
} from '../models/ClientTracking';
import { ConfigService } from './ConfigService';
import { isMobile } from 'react-device-detect';


declare var window: Window;

export class  ClientTrackingService {

    private readonly moduleName = 'Customer Web Registration';

    private readonly trackingEventStyle = 'background: #B8FDFF; color: #005EFF; padding: 5px';
    private readonly trackingObjectStyle = 'background: #F5DBFF; color: #630287; padding: 5px';
    private readonly trackingRenderStyle = 'background: #98F2C8; color: #03663F; padding: 5px';

    static serviceInstance: ClientTrackingService = null;

    private platform = require('platform');
    private moment = require('moment-timezone');

    private loginId: string = 'unknown';

    static getInstance(): ClientTrackingService {
        if (!this.serviceInstance) {
            this.serviceInstance = new ClientTrackingService();
        }

        return this.serviceInstance
    }

    private constructor(){
        ClientTrackingService.initialiseHTML();
    }

    public setLoginId(id: string) {
        this.loginId = id;
    }


    static initialiseHTML() {

        const configs = ConfigService.getInstance().values;
        const constants = configs.constants;

        if (!constants) {
            console.error('Error loading Constants. Unable to initialise Piwik');
            return;
        }

        const piwikTracking = constants.piwikTracking;
        const id = isMobile ? piwikTracking.mobile : piwikTracking.desktop;
        const piwikUrl = constants.PIWIK_URL;
        const scriptUrl = `${piwikUrl}piwik.js`;

        ClientTrackingService.loadGlobalTrackingCommands(piwikUrl, id);
        LazyLoaderUtil.addScriptTagToHead(scriptUrl, true);
    }

    private static loadGlobalTrackingCommands(url: string, id: number) {

        const paq = window._paq || (window._paq = []);

        if (window._paq.length && !_.includes(paq, 'setSiteId')) {
            console.log('piwik already initialised in window');
            return;
        }

        if (paq) {
            paq.push(['enableLinkTracking']);
            paq.push(['setTrackerUrl', `${url}piwik.php`]);
            paq.push(['setSiteId', id]);
        }
    }

    reportEvent(category, action, name, value, dimension, externalModule?: string, isRender?: boolean) {
        const configs = ConfigService.getInstance().values;
        const constants = configs.constants;

        if (isRender) {
            console.log(
                `%c**** TRACK EVENT :(${externalModule ? externalModule : 'Not Defined'}) - (${action}) - ${constants.PIWIK_CATEGORY}, ${constants.PIWIK_CATEGORY}_${name}, ${value ? `${value} ` : 'no value'}`, this.trackingRenderStyle);

            return;
        }

        console.log(`%c**** TRACK EVENT :(${externalModule}) - (${action}) - ${category}, ${category}_${name}, ${value ? `${value} ` : 'no value'}`, this.trackingObjectStyle);
    }


    private contructData(value: Object, type?: ClientTrackingActionType) {
        const configs = ConfigService.getInstance().values;
        const constants = configs.constants;

        const object = Object.assign(
            value || {},
            {
                appType: constants.APPLICATION_NAME,
                appVersion: constants.BUNDLE_VERSION,
                environment: constants.ENVIRONMENT,
                action: type,
                time: new Date().valueOf(),
                actionContext: this.metaData
            }
        ) || value;

        return object && JSON.stringify(object);
    }

    private get metaData() {
        const configs = ConfigService.getInstance().values;
        const constants = configs.constants;
        //@ts-ignore
        const connection = navigator?.connection;

        return {
            appVersion: constants.BUNDLE_VERSION,
            loginId: this.loginId,
            deviceId: null,
            deviceModel: null,
            deviceManufacturer: this.platform.manufacturer,
            userTimezone: this.moment.tz.guess(),
            os: this.platform.os,
            connectionInfo: {
                type:  connection?.type,
                effectiveType: connection?.effectiveType
            }
        }
    }

    trackEvent(name: ClientTrackingNameType, value?: Object) {
        const stringData = this.contructData(value, ClientTrackingActions.Data);
        const configs = ConfigService.getInstance().values;
        const constants = configs.constants;
        const category = constants.PIWIK_CATEGORY;

        this.reportEvent(
            category,
            ClientTrackingActions.Data,
            name,
            value || null,
            null,
            this.moduleName
        );

        window._paq.push(
            [
                'trackEvent',
                category,
                stringData,
                `${category}_${name}`
            ]
        );
    }

    trackClick(name: ClientTrackingNameType, value?: Object) {

        const stringData = this.contructData(value, ClientTrackingActions.Click);
        const configs = ConfigService.getInstance().values;
        const constants = configs.constants;

        this.reportEvent(
            constants.PIWIK_CATEGORY,
            ClientTrackingActions.Click,
            name,
            value || null,
            null,
            this.moduleName
        );

        window._paq.push(
            [
                'trackEvent',
                constants.PIWIK_CATEGORY,
                stringData,
                `${constants.PIWIK_CATEGORY}_${name}`
            ]
        );
    }


    trackRender(name: ClientTrackingNameType, value?: Object) {

        const stringData = this.contructData(value, ClientTrackingActions.Render);
        const configs = ConfigService.getInstance().values;
        const constants = configs.constants;

        this.reportEvent(
            constants.PIWIK_CATEGORY,
            ClientTrackingActions.Render,
            name,
            value || null,null,
            this.moduleName,
            true
        );

        window._paq.push(
            [
                'trackEvent',
                constants.PIWIK_CATEGORY,
                stringData,
                `${constants.PIWIK_CATEGORY}_${name}`
            ]
        );
    }
}
