import * as React from 'react';
import './styles.scss';
import { resources } from '../../resources/strings';
import { ConfigService } from '../../services/ConfigService';
import { SpinnerLoader } from '@swipejobs/frontend-react-core-web-components';

interface IProps {
}

function Marketing(props: IProps) {
    const config = ConfigService.getInstance().values;
    const marketingUrl = config.constants.marketingUrl;
    const strings = resources.marketing;

    return (
        <div className='marketing'>
            <div className='marketing-loader'>
                <div className='loader-container'>
                    <SpinnerLoader isLoading={true}
                                   loadingText={strings.loading}
                                   size={25} />
                </div>
            </div>
            {
                marketingUrl &&
                <iframe src={ marketingUrl }
                        title='marketing'
                        scrolling='no'>
                </iframe>
            }
        </div>
    );
}

export default Marketing;
