import {
    action,
    toJS,
    observable
} from 'mobx';
import { ClientTrackingService } from '../services/ClientTrackingService';
import { ClientTrackingNames } from '../models/ClientTracking';
import CustomerService, { ISubmitContactFormParams } from '../services/CustomerService';
import { isEmailValid } from '../utils/EmailValidation';
import { stripPhoneNumberFormatting } from '../utils/StripPhoneNumberFormatting';
import { resources } from '../resources/strings';
import { URLParamStore } from './URLParamStore';
import { UrlParams, UrlParamTypes } from '../models/UrlParams';
import { ConfigService } from '../services/ConfigService';
import DataTrackingService  from '../services/DataTrackingService';

const prodUrlPrefix = 'https://customer.swipejobs.com/';
const defaultTime = '09:00'

export class ContactFormStore {
    @observable public name = '';
    @observable public email = '';
    @observable public cellNumber = '';
    @observable public message = '';
    @observable public date = '';
    @observable public showDate = false;
    @observable public time = defaultTime;
    @observable public showTime = false;
    @observable public isValid = false;
    @observable public isLoading = false;

    @observable public isModalOpen = false;
    @observable public modalTitle = '';
    @observable public modalBody = '';

    @observable public urlParams: UrlParams;

    private strings = resources.contactForm;
    public clientTracking: ClientTrackingService;

    constructor(private $customerService: CustomerService,
                private $dataTrackingService: DataTrackingService,
                private $urlParamStore: URLParamStore) {
        this.clientTracking = ClientTrackingService.getInstance();
        this.updateUrlParams();
        this.updateDate(this.getDate());
    }

    private getDate() {
        const tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);
        const addLeadingZero = (date: number) => `${date < 10 ? `0` : ''}${date}`;
        const year = tomorrow.getFullYear();
        const month = addLeadingZero(tomorrow.getMonth() + 1);
        const date = addLeadingZero(tomorrow.getDate());
        return `${year}-${month}-${date}`;
    }

    @action.bound
    updateUrlParams() {
        this.urlParams = this.$urlParamStore.getMarketingParams();
    }

    @action.bound
    updateName(name: string) {
        this.name = name;
    }

    @action.bound
    updateEmail(email: string) {
        this.email = email;
    }

    @action.bound
    updateCellNumber(cellNumber: string) {
        this.cellNumber = cellNumber;
    }

    @action.bound
    updateMessage(message: string) {
        this.message = message;
    }

    @action.bound
    updateTime(time: string) {
        this.time = time;
    }

    @action.bound
    updateShowTime(showTime: boolean) {
        this.showTime = showTime;
    }

    @action.bound
    updateDate(date: string) {
        this.date = date;
    }

    @action.bound
    updateShowDate(showDate: boolean) {
        this.showDate = showDate;
    }

    @action.bound
    async setIsValid() {
        this.isValid = !!this.name &&
            isEmailValid(this.email) &&
            stripPhoneNumberFormatting(this.cellNumber).length === 10 &&
            !!this.message;
    }

    @action.bound
    async closeModal() {
        this.isModalOpen = false;
    }

    @action.bound
    private openModal(title: string, body: string) {
        this.isModalOpen = true;
        this.modalTitle = title;
        this.modalBody = body;
    }

    @action.bound
    submitClicked() {
        if (!this.isValid) {
            return;
        }

        this.clientTracking.trackClick(ClientTrackingNames.ContactFormSubmit);
        this.submitAsync();
    }

    private async submitAsync() {
        await this.submitContactFormAsync();
        await this.sendTrackingData();
    }

    private getContactFormParams(): ISubmitContactFormParams {
        const urlParams = toJS(this.urlParams);
        const registrationUrl = this.getUrl();
        const {
            utm_id: utmId,
            utm_campaign: utmCampaign,
            utm_medium: utmMedium,
            utm_source: utmSource,
            rsCode,
            programId
        } = urlParams;

        return  {
            name: this.name,
            emailAddress: this.email,
            phoneNumber: stripPhoneNumberFormatting(this.cellNumber),
            message: this.message,
            date: this.showDate ? this.date : null,
            time: this.showTime ? this.time : null,
            utmId,
            rsCode,
            utmCampaign,
            programId,
            utmMedium,
            utmSource,
            registrationUrl
        }
    }

    private async sendTrackingData() {
        const contactFormParams: ISubmitContactFormParams = this.getContactFormParams();
        this.resetForm();

        const {
            href,
            search
        } = window.location;
        const source = href.replace(search, '');

        try {
            await this.$dataTrackingService.sendTracking(contactFormParams, source);
        } catch (e) {
        }
    }

    private async submitContactFormAsync() {
        const params: ISubmitContactFormParams = this.getContactFormParams();

        try {
            this.isLoading = true;
            await this.$customerService.submitContactForm(params);
            this.clientTracking.trackClick(ClientTrackingNames.ContactFormSuccess);
            this.showSuccessModal();
        } catch (e) {
            this.clientTracking.trackClick(ClientTrackingNames.ContactFormFail);
            this.showErrorModal();
        } finally {
            this.isLoading = false;
        }
    }

    private resetForm() {
        this.updateName('');
        this.updateEmail('');
        this.updateCellNumber('');
        this.updateMessage('');
        this.updateMessage('');
        this.updateTime(defaultTime);
        this.updateDate(this.getDate());
    }

    private getUrl() {
        const urlParams = toJS(this.urlParams);
        const config = ConfigService.getInstance().values;
        const hostUrl = process.env.REACT_APP_ENVIRONMENT === 'prod' ? prodUrlPrefix :
            config.constants.HOST_URL
                .replace('api-', '')
                .replace('v3', 'customer');

        let url = `${hostUrl}?${UrlParamTypes.Term}=register`;
        url = `${url}&${UrlParamTypes.RsCode}=${urlParams.rsCode}`;
        url = `${url}&${UrlParamTypes.Campaign}=${urlParams.utm_campaign}`;
        url = `${url}&${UrlParamTypes.Medium}=${urlParams.utm_medium}`;
        url = `${url}&${UrlParamTypes.ProgramId}=${urlParams.programId}`;
        url = `${url}&${UrlParamTypes.Source}=${urlParams.utm_source}`;
        url = `${url}&${UrlParamTypes.Content}=${urlParams.utm_content}`;
        url = `${url}&${UrlParamTypes.Id}=${urlParams.utm_id}`;
        url = `${url}#/app/newCustomerRegistrationDetails`;
        return url;
    }

    private showErrorModal() {
        this.openModal(this.strings.errorModalTitle, this.strings.errorModalBody);
    }

    private showSuccessModal() {
        this.openModal(this.strings.successModalTitle, this.strings.successModalBody);
    }
}
