import React from 'react';
import { resources } from '../../resources/strings';
import './styles.scss';

interface IProps {
    isCentred?: boolean;
}

class Header extends React.Component<IProps> {
    render() {
        const headerStrings = resources.header;

        return (
            <div className={`sj-header ${ this.props.isCentred ? 'centred' : ''}`}>
                <div className='logo' />
                <div className='header-title'>
                    {headerStrings.title}
                </div>
            </div>
        );
    };
}

export default Header;
