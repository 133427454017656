import {
    HttpService,
    IApiConfigurationService
} from '@swipejobs/frontend-comms-services';
import TokenService from './TokenService';
import { ISubmitContactFormParams } from './CustomerService';

export interface ITrackingParams {
    attributes: ISubmitContactFormParams;
    source: string;
    type: string
}

const trackingType = 'customer enquiry submitted';

export default class DataTrackingService {
    constructor(private apiConfigurationService: IApiConfigurationService,
                private httpService: HttpService,
                private tokenService: TokenService) {
    }

    async sendTracking(attributes: ISubmitContactFormParams, source: string) {
        const params: ITrackingParams = {
            attributes,
            source,
            type: trackingType
        }

        const api = this.apiConfigurationService.buildApiUrl( 'identity/external/tracking/');
        const response = await this.httpService.post(api, params, this.getHeaders());
        const result = response.json();
    }

    private getHeaders() {
        const authorization = this.tokenService.guestAuthorizationToken;
        return {
            authorization,
            'Request-Origin': 'CUSTOMER',
            'reqo': 'CUSTOMER-DESKTOP',         //todo replace these with proper comms service fix
            'pid': 'SJ'
        }
    }
}
