//xxx copied from ionic frontend - worker/develop branch
import {
    PinCodeModel,
    pinCodeNotificationTypeMap, PinCodeRequest,
    PinCodeRequestType,
    PinCodeServiceType,
    PinRequestReferrerPage, SendCodeResponse
} from '../models/PinCodeModels';
import { RequestContext } from '../models/RequestContext';
import { stripPhoneNumberFormatting } from '../utils/StripPhoneNumberFormatting';
import { HttpService, IApiConfigurationService } from '@swipejobs/frontend-comms-services';
import _ from 'lodash';
import TokenService from './TokenService';
import { UrlParams } from '../models/UrlParams';

export class PinCodeService {
    constructor(private apiConfigurationService: IApiConfigurationService,
                private httpService: HttpService,
                private tokenService: TokenService) {
    }

    async trySendCodeAsync(model: PinCodeModel, options: {
        pinCodeServiceType: PinCodeServiceType;
        referrerPage?: PinRequestReferrerPage;
        requestContext?: RequestContext;
        customerConsent?: boolean;
        referralParams?: UrlParams;
        requestType?: PinCodeRequestType;
        registrationRequestId?: string;
        errorCodesMessages?: {
            [key: string]: {
                title: string;
                message: string;
            };
        };
    }): Promise<SendCodeResponse> {
        const {
            pinCodeServiceType,
            referrerPage,
            requestContext,
            referralParams,
            customerConsent,
            registrationRequestId,
            requestType
        } = options;

        const context = {
            ...referralParams,
            businessAddress: model.businessAddress,
            companyName: model.companyName,
            ein: model.ein,
            email: model.email,
            firstName: model.firstName,
            lastName: model.lastName,
            phoneNumber: model.phoneNumber,
            pinCodeContextType: 'NEW_REGISTER',
            registrationRequestId: registrationRequestId,
            registrationType: 'NEW_CUSTOMER',
            username: model.email
        }

        try {
            const phoneNumber = model.phoneNumber && stripPhoneNumberFormatting(model.phoneNumber);
            const params = {
                phoneNumber,
                firstName: model.firstName,
                lastName: model.lastName,
                emailAddress: model.email,
                customerConsent:_.isBoolean(customerConsent) ? customerConsent :
                                _.isBoolean(model.legalCheckbox) ? model.legalCheckbox :
                                _.isBoolean(model.smsConsent) ? model.smsConsent :
                                _.isBoolean(model.customerConsent) ? model.customerConsent :
                                true,
                currentPage: referrerPage,
                requestContext,
                context,
                preferredNotificationType: pinCodeNotificationTypeMap[pinCodeServiceType],
                requestType
            };

            model.externalId && Object.assign(params, {
                clientId: model.externalId
            });

            const response = await this.registerPinCodeRequestAsync(params);

            if (!response.transactionId) {
                return {
                    destination: null,
                    errorCode: response.errorCode
                }
            }

            model.transactionId = response.transactionId;
            model.phoneNumber = phoneNumber;

            return {
                destination: response.userAddress,
                transactionId: response.transactionId
            };

        } catch(error) {
            //todo sentry and handle error
            return {
                destination: null,
                errorCode: 'unknown'
            };
        }
    }

    private async registerPinCodeRequestAsync(request: PinCodeRequest) {
        const {
            phoneNumber,
            customerConsent,
            clientId,
            emailAddress,
            firstName,
            lastName,
            context,
            preferredNotificationType
        } = request;

        const api = this.apiConfigurationService.buildApiUrl( 'identity/pinCodeRequest/register');
        const body = {
            clientId,
            consent: customerConsent,
            emailAddress,
            loginId: emailAddress,
            firstName,
            lastName,
            context,
            phoneNumber,
            preferredNotificationType
        };


        const authorization = this.tokenService.guestAuthorizationToken;
        const response = await this.httpService.post(api, body, {
            authorization,
            'Request-Origin': 'CUSTOMER',
            'reqo': 'CUSTOMER-DESKTOP',         //todo replace these with proper comms service fix
            'pid': 'SJ'
        });
        return await response.json();
    }
}
