import React from 'react';
import parse from 'html-react-parser'
import { resources } from '../../resources/strings';
import './styles.scss';
import { ConfigService } from '../../services/ConfigService';

const Footer = () => {

    const strings = resources.customerLandingPage;
    const configs = ConfigService.getInstance().values;
    const calendlyUrl = configs.constants.CALENDLY_URL;

    return (
        <div className='footer-sj'>
            <div className='top-container'>
                <h2>{parse(strings.footerTitle)}</h2>
                <a href='#register'>
                    <button className='button-sj'>
                        <h2>{strings.footerButtonLabel}</h2>
                    </button>
                </a>
            </div>
            <div className='bottom-container'>
                <div className='logo-container'>
                    <div className='logo'/>
                </div>
                <div className='social-media-container'>
                    <a className='social-item'
                       href={configs.constants.FACEBOOK_URL}
                       target='_blank'>
                        <img src='/images/Button_FB.png'/>
                    </a>
                    <a className='social-item'
                       href={configs.constants.INSTAGRAM_URL}
                       target='_blank'>
                        <img src='/images/Button_IG.png'/>
                    </a>
                    <p>{strings.footerFooter}</p>
                </div>
                <div className='left-footer-invisible'>

                </div>
            </div>
        </div>
    );

}

export default Footer;
