import React from 'react';
import parse from 'html-react-parser'
import './styles.scss';

interface IProps {
    imageOnRight?: boolean;
    title: string;
    content: string;
    image: string;
}

const ImageInformationCard = ({
    imageOnRight,
    title,
    content,
    image
}: IProps) => {
    return (
        <div className={imageOnRight ? 'card-container-wrapper__image-on-right' : 'card-container-wrapper'}>
            <div className={imageOnRight ? 'card-container__image-on-right' : 'card-container'} >
                <img src={`/images/${image}`}/>
                <div className='info-card'>
                    <h2>{parse(title)}</h2>
                    <p>{parse(content)}</p>
                </div>
            </div>
        </div>
    );

}

export default ImageInformationCard;
