export const resources = {
    marketing: {
        loading: 'Loading'
    },
    contactForm: {
        title: 'let\'s get your work needs sorted.',
        subTitle: 'randstad makes it easy for your business to fulfil its labor needs on-demand! Just fill out the form below to get started.',
        name: 'name',
        email: 'email address',
        phoneNumber: 'phone number',
        message: 'Tell us more about your employment needs',
        callBack: 'preferred call back time',
        submit: 'submit',
        errorModalTitle: 'Error',
        errorModalBody: 'Sorry, an error occurred. Please try again later.',
        successModalTitle: 'success',
        successModalBody: 'Thank you for your enquiry. We\'ll be back in contact with you shortly.'
    },
    immediateDownloadPage: {
        downloadCongratulations: "",
        appStoreAutoLinking: "You will shortly be directed to the app store, if this fails please click the link below",
        appStoreLinking: 'Please download the mobile app from the app store link which is matched to your device. ',
        qrDescription: 'Please download the mobile app by scanning the QR code using your iOS or Android device. Once you have downloaded this please login with the details you just set up to get working.',
        description: 'Not using your mobile phone? No problem, register using this device and download the app later.',
        descriptionDeviceDetected: 'Please use the link above to download the randstad at work app from the app store.',
        downloadNowLabel: 'Download Now',
        redirectLabel1: 'Redirecting automatically in {0} seconds. Click ',
        redirectLabel2: 'here',
        redirectLabel3: ' to not redirect.',
        registerButtonLabel: 'Register Now'
    },
    landingPage1: {
        section1: {
            title: 'Access qualified workers 24x7',
            body: 'randstad lets you access on-demand workers at the tap of a button. Order today and watch as qualified workers take your job - it’s that simple!'
        },
        section2: {
            title: 'the benefits',
            items: [
                {
                    title: 'fill more orders',
                    body: 'randstad digital platform matches and notifies workers in less than 1 second.'
                },{
                    title: 'available 24/7',
                    body: 'day or night you have access to randstad on-demand workers in your area. The control is in your hands.'
                },{
                    title: 'simple & easy',
                    body: 'order on-demand workers today and pay when the job is done.'
                },{
                    title: 'choice & control',
                    body: 'after the shift is done, enter ratings and hours for workers. Want the same workers again? Invite your favorite workers back at the touch of a button.'
                }
            ]
        },
        section3: {
            title: 'how does it work?',
            items: [
                {
                    title: 'create a randstad account',
                    body: 'Sign up on your computer or download the mobile app. In a few steps you’ll be ready to place orders!'
                }, {
                    title: 'create an order',
                    body: 'Build your order from the ground up. Select the job requirements and the number of workers you need. We’ll provide an instant quote'
                }, {
                    title: 'catch as your order fills',
                    body: 'Watch as workers dispatch to your shifts in real time'
                }, {
                    title: 'submit ratings and hours',
                    body: 'Once the shift is over simply add hours for each worker and rate their performance'
                }, {
                    title: 'manage your orders and invite workers back',
                    body: 'Like a worker? Invite them back onto new orders, or extend them onto future shifts'
                }
            ]
        }
    },
    landingPage2: {
        section1: {
            title: 'access qualified workers 24x7',
            body: 'randstad lets you access on-demand workers at the tap of a button. Order today and watch as qualified workers take your job - it’s that simple!'
        },
        section2: {
            items: [
                {
                    title: 'fill more orders',
                    body: 'randstad digital platform matches and notifies workers in less than 1 second.'
                },{
                    title: 'available 24/7',
                    body: 'Day or night you have access to randstad on-demand workers in your area. The control is in your hands.'
                },{
                    title: 'simple & easy',
                    body: 'Order on-demand workers today and pay when the job is done.'
                },{
                    title: 'choice & control',
                    body: 'After the shift is done, enter ratings and hours for workers. Want the same workers again? Invite your favorite workers back at the touch of a button.'
                }
            ]
        }
    },
    landingPage4: {
        subtitle1: 'great people. great jobs.',
        subtitle2: 'right now.',
        intro: 'Introducing <b>randstad at work</b>— 24/7 access to our new digital platform that puts you in the driver’s seat, allowing you to take control of your shifts and secure great manufacturing and logistics workers in minutes.',
        pointsTitle: '<b>randstad at work</b> provides you with:',
        points: [
            'immediate connection to post open jobs/shifts and get them in front of top candidates in your market',
            'the power to choose when, where and how to work with Randstad',
        ],
        findShifts: 'Fill your shifts faster — anytime, anywhere — with our top manufacturing talent.',
        oppotunity: 'We’re excited to offer this new 24/7 option to connect you directly with great people — right now.',
        download: 'Download the app here and get started right away!',
        direction: '*if you’re a candidate looking for jobs, please download our candidate app <a id="download">here</a>.',
        downloadButton: "download randstad at work"
    },
    registrationComponent: {
        title: 'Let\'s get your work needs sorted.',
        subTitle: 'randstad makes it easy for your business to fulfil its labor needs on-demand! Just fill out the form below to get started.',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Work Email Address',
        cellNumber: 'Cell Number',
        companyName: 'Company Name',
        address: 'Search for an address',
        businessEin: 'Business EIN (optional)',
        buttonLabel: 'Register'
    },
    header: {
        title: 'client'
    },
    disclaimer: {
        text: 'By clicking "Register" I agree that randstad or its representatives may contact me by email, phone or SMS at the email address or number I provide, including for marketing purposes, and that my information will be used in accordance with randstad',
        title: 'Privacy Policy',
    },
    locationSearchInput: {
        currentLocation: 'current location',
        noAddressesFound: 'sorry, we can\'t find that address. Please check your search.'
    },
    signUpPage: {
        title: 'client'
    },
    checkYouEmailComponent: {
        title: 'check your email',
        subTitle: 'To get you started we just need to verify your email. We have sent you an email, please verify your email address by clicking the link in the email.',
        resendEmail: 'resend email'
    },
    contactUs: {
        title: 'contact us',
        callSupportCenter: 'call our support center on:',
        emailSupportCenter: 'or you can email us on:',
    },
    pinCodePage: {
        title: 'register',
        registerButtonLabel: 'register',
        invalidCodeError: 'Invalid PIN code entered. Please retry or request to receive a new PIN by voice call or SMS',
        errorModalTitle: 'error',
        errorModalBody: 'sorry, an error occurred. Please try again later.',
        pinResentTitle: 'success',
        pinResentBody: 'A new PIN code has been emailed to you.'
    },
    downloadPage: {
        downloadCongratulations: "congratulations, your account has been successfully created.",
        description: 'Please download the mobile app from the app store link which is matched to your device. Once you have downloaded this please login with the details you just set up to get working.',
        descriptionDeviceDetected: 'Please use the link above to download the randstad at work app from the app store. Once you have downloaded this please login with the details you just set up to get working.',
        downloadNowLabel: 'download now',
        redirectLabel1: 'redirecting automatically in {0} seconds. click ',
        redirectLabel2: 'here',
        redirectLabel3: ' to not redirect.'
    },
    registration: {
        description: "Please Enter Your Details and Cell number below for access to jobs 24x7.",
        firstNameLabel: "first name",
        lastNameLabel: "last name",
        cellNumberLabel: "cell number",
        hintTextLabel: "enter text",
        buttonLabel: "register",
        phoneHint: "(000) 000-0000",
        appearsCheckLabel: "Please enter your name as it appears on your ID or Passport",
        validateLabel: "A PIN will be sent to your cell to validate your number",
        legalSmsCheck: "By checking this box, I give my electronic signature authorizing randstad, its affiliates, subsidiaries, agents, parents, and service providers, to contact me via live agent, prerecorded, or artificial voice call and text (SMS) message, and send me advertising and other calls or messages regarding potential jobs opportunities, mobile application updates, and promotional materials to my phone number that I provided above using an automatic telephone dialing system. I understand that I do not need to sign this agreement, or otherwise agree, as a condition of purchasing any property, goods, or services.",
        errorModalTitle: 'error',
        errorNumberUsedModalTitle: 'This phone number has already been registered.',
        errorInitialiseRegoMessage: 'Error occurred during data capture initialization. Please try again later.',
        errorLocalityBody: 'We don\'t service job sites around your location quite yet. We are working hard on expanding and will be in your area soon!',
        errorMatchModalBody: 'Your name does not match the name we have on file for you. Please make sure that you enter your full name, as it presents on your ID.',
        errorModalBody: 'sorry, an error occurred. Please try again later.',
        successModalTitle: 'success',
        successModalBody: 'To get you started we just need to verify your email. We have sent you an email, please verify your email address by clicking the link in the email.',
    },
    customerLandingPage: {
        pageTitle: '<b>Upgrade</b> <br> your staffing',
        pageDescription: 'with the digital solution that gives you the control you need and the software to manage it. With swipejobs, you`ll never have to worry about staffing again.',
        formTitle: 'Request a demo',
        firstNameLabel: "First Name",
        lastNameLabel: "Last Name",
        emailLabel: "Email",
        phoneLabel: "Phone",
        companyLabel: "Company",
        jobTitleLabel: "Job Title",
        jobTitleOptionalLabel: '- Optional',
        stateLabel: "State",
        buttonLabel: "Get Started",
        phoneHint: "(000) 000-0000",
        imageCards: [
            {
                title: 'Fill your positions in record time',
                description: 'With a click of a button your order gets sent out in seconds. Filling yours and <b>50,000 other positions every day.</b>',
                image:'Image_1.png'
            },
            {
                title: 'Find the best of the best',
                description: 'With <b>1 million registered workers</b> (and counting) we can find the right workers to fit your business needs.',
                image:'Image_2.png',
                imageOnRight: true
            },
            {
                title: 'Experience that helps you',
                description: 'We work with <b>147,000 Businesses daily</b> so we know a thing or two and are continually improving (with 3,000 updates last year alone) to bring you the best digital platform for you and your workforce.',
                image:'Image_3.png'
            },
        ],
        footerTitle: 'Ready for <b>the future?</b>',
        footerButtonLabel: 'Get a Demo',
        footerFooter: 'swipejobs © 2022'
    },
    createPassword: {
        passwordMessage: 'Please choose a password for your account. The password must:',
        passwordPlaceholder: 'password',
        passwordErrorMessage: 'this password does not meet the requirements.',
        confirmPasswordPlaceholder: 'confirm Password',
        confirmationPasswordErrorMessage: 'passwords do not match.',
        moreThanEightRule: "have at least 8 characters",
        specialCharacterRule: "include either a capital letter or special character.",
        lowerCaseRule: "include a lower case letter",
        numberRule: "include a number",
        showMessage: 'show password',
        hideMessage: 'hide password'
    },
    pinCode: {
        instructionLabel: 'Please enter the 6-digit PIN that was sent to',
        pinLabel: 'pin',
        enterPinLabel: 'enter pin',
        resendViaLabel: 'resend via:',
        smsLabel: 'SMS',
        callLabel: 'voice call'
    },
    unsubscribe: {
        loading: 'Page loading...',
        successfullyUnsubscribed: 'You have successfully been unsubscribed from this email list.',
        wishToSubscribeAgain: 'If you wish to subscribe again you can update your notification preferences in your app',
        failedToUnsubscribe: 'There was an issue with unsubscribing you from this mailing list, please try again from the email',
        partnerLogoAltText: 'randstad logo'
    }
};
