import { action, observable } from 'mobx';
import { PinCodeService } from '../services/PinCodeService';
import { PinCodeModel, PinCodeServiceType, PinCodeServiceTypes, PinRequestReferrerPages } from '../models/PinCodeModels';
import { ClientTrackingNames } from '../models/ClientTracking';
import { ClientTrackingService } from '../services/ClientTrackingService';
import { resources } from '../resources/strings';
import TokenService from '../services/TokenService';
import { URLParamStore } from './URLParamStore';

export class PinCodeStore {

    @observable public pinCode: string;
    @observable isLoading: boolean = false;
    @observable incorrectCodeError: boolean = false;

    @observable public isModalOpen: boolean = false;
    @observable public modalTitle: string = '';
    @observable public modalBody: string = '';

    private strings = resources.pinCodePage;

    public clientTracking: ClientTrackingService;

    constructor(private $pinCodeService: PinCodeService,
                private tokenService: TokenService,
                private urlParamStore: URLParamStore,
                private navigateToDownload: () => void,
                private pinCodeModel: PinCodeModel) {
        this.clientTracking = ClientTrackingService.getInstance();
    }

    @action.bound
    wipePinCode() {
        this.pinCode = '';
    }

    @action.bound
    async resendVoiceCodeClicked() {
        await this.resendPin(PinCodeServiceTypes.Call);
    }

    @action.bound
    async closeModal() {
        this.isModalOpen = false;
    }

    @action.bound
    async resendPinCodeClicked() {
        await this.resendPin(PinCodeServiceTypes.Email);
    }


    private async resendPin(type: PinCodeServiceType) {
        this.clientTracking.trackClick(ClientTrackingNames.PinResendCode);

        this.isLoading = true;
        const response = await this.$pinCodeService.trySendCodeAsync(
            this.pinCodeModel,
            {
                pinCodeServiceType: type,
                referrerPage: PinRequestReferrerPages.InitialRegistrationPin,
                requestContext: null//RequestContextType.Registration
            });

        if (!response.destination) {
            this.showErrorModal();
            this.isLoading = false;
            return;
        }

        this.showPinResentModal();

        // TODO
        // const event = type === PinCodeServiceTypes.Email ?
        //     ClientTrackingNames.PinCodeSendCodeSMS :
        //     ClientTrackingNames.PinCodeSendCodeCall;
        //
        // this.clientTracking.trackEvent(event, {cell: this.pinCodeModel.phoneNumber});
        this.isLoading = false;
    }

    private showErrorModal() {
        this.isModalOpen = true;
        this.modalTitle = this.strings.errorModalTitle;
        this.modalBody = this.strings.errorModalBody;
    }

    private showPinResentModal() {
        this.isModalOpen = true;
        this.modalTitle = this.strings.pinResentTitle;
        this.modalBody = this.strings.pinResentBody;
    }
}
